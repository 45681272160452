/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S.
 * and Foreign Patents, patent applications, and are protected by trade secret
 * or copyright law. Dissemination of this information, reproduction of this
 * material, and copying or distribution of this software is strictly forbidden
 * unless prior written permission is obtained from Pearson Education, Inc.
 */

import React, { Fragment, useEffect } from 'react';
import { observer, inject } from 'mobx-react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { LockIcon } from '../../custom/icons/LockIcon';
import Header from '../Header/Header';
import Constants from '../../constants';
import serviceDownPageConstants from '../../constants/serviceDownPageConstants';
import './ServiceDown.style.scss';

const ServiceDown = props => {
  const {
    info,
    store: {
      Header: { title, setTitle }
    }
  } = props;
  const { BROWSER_TITLE, PROJECT_TITLE } = Constants;
  const {
    SERVICE_DOWN_TITLE,
    SERVICE_DOWN_MESSAGE1,
    SERVICE_DOWN_MESSAGE2,
    SERVICE_DOWN_DEFAULT_MESSAGE
  } = serviceDownPageConstants;

  // for service down page title should be default
  useEffect(() => {
    setTitle(PROJECT_TITLE);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [title]);

  return (
    <Fragment>
      <Helmet>
        <title>{BROWSER_TITLE}</title>
      </Helmet>
      <Header hideUserDetail />
      <div className="service-down-page-wrapper">
        <div>
          <p className="welcome-text"> {SERVICE_DOWN_TITLE}</p>
          <p>{SERVICE_DOWN_MESSAGE1}</p>
          <p>{SERVICE_DOWN_MESSAGE2}</p>
          <div className="additional-msg">
            <div>{info || SERVICE_DOWN_DEFAULT_MESSAGE}</div>
          </div>
        </div>
        <div className="forbidden-lock-image">
          <LockIcon fontSize="large" />
        </div>
      </div>
    </Fragment>
  );
};

ServiceDown.propTypes = {
  info: PropTypes.string,
  store: PropTypes.shape({
    Header: PropTypes.shape({
      title: PropTypes.string,
      setTitle: PropTypes.func.isRequired
    })
  })
};

export default inject('store')(observer(ServiceDown));
