/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S.
 * and Foreign Patents, patent applications, and are protected by trade secret
 * or copyright law. Dissemination of this information, reproduction of this
 * material, and copying or distribution of this software is strictly forbidden
 * unless prior written permission is obtained from Pearson Education, Inc.
 */

/* eslint-disable react/jsx-wrap-multilines */
import React, { useState, Fragment } from 'react';
import uuidv4 from 'uuid/v4';
import PropTypes from 'prop-types';
import {
  Button,
  Checkbox,
  IconButton,
  FormControlLabel,
  TextField,
  InputAdornment
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { cloneDeep } from 'lodash';
import ReactDatePicker from 'react-datepicker';
import DateRangeOutlinedIcon from '@material-ui/icons/DateRangeOutlined';
import ImageBox from './ImageBox';
import {
  addReleaseContent,
  saveGoLiveSettings
} from '../../services/addReleaseContent';
import './ReleaseManagement.style.scss';
import NewReleaseDialog from '../new-release-dialog/NewReleaseDialog';
import Constants from '../../constants';
import { convertImageIntoBase64 } from '../../utils';
import { handleDragStart, handleDragOver, handleDrop } from '../../utils/utils';
import 'react-datepicker/dist/react-datepicker.css';

const ReleaseManagement = props => {
  const { handleClose } = props;
  const [images, setImages] = useState([]);
  const imgObj = [];
  const imgArray = [];
  const today = new Date().setHours(0, 0, 0);
  const [startDate, setStartDate] = useState(new Date(today));
  const [endDate, setEndDate] = useState(new Date(today));
  const [visible, setVisible] = useState(true);
  const [releaseName, setReleaseName] = useState(null);
  const [preview, setPreview] = useState(false);

  // For previewing the uploaded image in what's new dialog
  const handlePreview = () => {
    setPreview(true);
  };

  // Setting the value of checkbox after click on "visible to users"
  const handleCheck = e => {
    setVisible(e.target.checked);
  };

  /**
   * To upload images after selecting from your local system
   * @param {*} e : event
   */
  const uploadMultipleFiles = async e => {
    e.preventDefault();
    imgObj.push(e.target.files);
    const imageUrlPromises = [];
    for (let i = 0; i < imgObj[0].length; i += 1) {
      imageUrlPromises.push(convertImageIntoBase64(imgObj[0][i]));
    }
    const imageUrls = await Promise.all(imageUrlPromises);
    for (let i = 0; i < imageUrls.length; i += 1) {
      const formatObj = {
        id: images.length > 0 ? images.length + i : i,
        url: imageUrls[i]
      };
      imgArray.push(formatObj);
    }
    imgArray.unshift(...images);
    setImages(imgArray);
  };

  /**
   * To Swap the uploaded images bewteen each other
   * @param {*} fromBox - source place
   * @param {*} toBox - destination place
   */
  const swapBoxes = (fromBox, toBox) => {
    const boxes = images.slice();
    let fromIndex = -1;
    let toIndex = -1;
    for (let i = 0; i < boxes.length; i += 1) {
      if (boxes[i].id === fromBox.id) {
        fromIndex = i;
      }
      if (boxes[i].id === toBox.id) {
        toIndex = i;
      }
    }
    if (fromIndex !== -1 && toIndex !== -1) {
      const { fromId, ...fromRest } = boxes[fromIndex];
      const { toId, ...toRest } = boxes[toIndex];
      boxes[fromIndex] = { id: fromBox.id, ...toRest };
      boxes[toIndex] = { id: toBox.id, ...fromRest };
      setImages(boxes);
    }
  };

  const clearEventForUploadingSameImage = e => {
    e.target.value = null;
  };

  // To delete uploaded images from UI
  const handleImageDelete = index => {
    const filterData = images.filter(item => item.id !== index);
    const result = [];
    filterData.forEach((item, i) => {
      const formatObj = {
        id: i,
        url: item.url
      };
      result.push(formatObj);
    });
    setImages(result);
  };

  /**
   * To save images in DB after uploading from local system on UI
   */
  const handleSave = async () => {
    const requestId = uuidv4();
    const imageArray = cloneDeep(images);
    if (images.length > 0) {
      await addReleaseContent(
        images[0].url,
        releaseName,
        images[0].id,
        requestId
      );
      if (images.length > 1) {
        imageArray.splice(0, 1);
        const promises = imageArray.map(image =>
          addReleaseContent(image.url, releaseName, image.id, requestId)
        );
        await Promise.all(promises);
      }
    }
    if (visible && startDate && endDate) {
      await saveGoLiveSettings(releaseName, startDate, endDate);
    }
  };

  const previewImages = { newReleaseContent: images };

  const closeNewReleaseDialog = data => {
    setPreview(data);
  };

  const hanldeReleaseName = e => setReleaseName(e.target.value);

  const body = (
    <div className="dialog modal-style">
      <form className="formdesign">
        <br />
        <div className="settings">
          <TextField
            autoComplete="off"
            required
            autoFocus
            id="releaseName"
            label={Constants.RELEASE_NAME}
            type="text"
            value={releaseName}
            onChange={hanldeReleaseName}
            data-testid="releaseName"
            inputProps={{
              'aria-label': 'release-management-release-name'
            }}
          />

          <div className="input-wrapper-date">
            <ReactDatePicker
              selected={startDate}
              onChange={date => setStartDate(date)}
              timeInputLabel={Constants.TIME}
              dateFormat="dd-MM-yyyy hh:mm aa"
              minDate={new Date()}
              customInput={
                <TextField
                  autoComplete="off"
                  type="text"
                  className="input-style"
                  label={Constants.START_DATE}
                  selected={startDate}
                  InputLabelProps={{
                    for: 'startDate',
                    shrink: true,
                    required: true
                  }}
                  InputProps={{
                    id: 'startDate',
                    readOnly: true,
                    endAdornment: (
                      <InputAdornment position="end">
                        <DateRangeOutlinedIcon />
                      </InputAdornment>
                    )
                  }}
                  data-testid="startDate"
                  required
                />
              }
              todayButton={Constants.TODAY}
              showMonthDropdown
              showYearDropdown
              showTimeInput
            />
          </div>

          <div className="input-wrapper-date">
            <ReactDatePicker
              selected={endDate}
              onChange={date => setEndDate(date)}
              timeInputLabel={Constants.TIME}
              dateFormat="dd-MM-yyyy hh:mm aa"
              minDate={new Date()}
              customInput={
                <TextField
                  autoComplete="off"
                  type="text"
                  className="input-style"
                  label={Constants.END_DATE}
                  selected={endDate}
                  InputLabelProps={{
                    for: 'endDate',
                    shrink: true,
                    required: true
                  }}
                  InputProps={{
                    id: 'endDate',
                    readOnly: true,
                    endAdornment: (
                      <InputAdornment position="end">
                        <DateRangeOutlinedIcon />
                      </InputAdornment>
                    )
                  }}
                  data-testid="endDate"
                  required
                />
              }
              todayButton={Constants.TODAY}
              showMonthDropdown
              showYearDropdown
              showTimeInput
            />
          </div>
          <div className="check-box-style">
            <FormControlLabel
              control={
                <Checkbox
                  checked={visible}
                  onChange={handleCheck}
                  color="primary"
                  inputProps={{
                    'aria-label': 'release-management-visible-to-users-checkbox'
                  }}
                />
              }
              label={Constants.VISIBLE_TO_USERS}
            />
          </div>
        </div>
        <br />
        <div className="upload-image">
          <input
            type="file"
            required
            id="fileInput"
            className="file-input"
            multiple
            accept="image/*"
            data-testid="fileInput"
            onChange={e => uploadMultipleFiles(e)}
            onClick={event => clearEventForUploadingSameImage(event)}
          />
          <Button
            color="primary"
            data-testid="btn-choose-files"
            onClick={() => {
              document.getElementById('fileInput').click();
            }}
            className="btn-choose-files"
            aria-label="release-management-choose-files"
          >
            {Constants.CHOOSE_FILES}
          </Button>
          <Button
            color="primary"
            onClick={handlePreview}
            className="preview-btn"
            aria-label="release-management-preview"
          >
            {Constants.PREVIEW}
          </Button>
        </div>
        <div className="image-wrapper">
          {images &&
            images.length > 0 &&
            images.map((data, index) => {
              return (
                <Fragment key={data.id}>
                  <ImageBox
                    data={data}
                    id={index}
                    key={data.id}
                    data-testid="imageBox"
                    draggable="true"
                    onDragStart={dragData =>
                      handleDragStart(dragData, 'dragContent')
                    }
                    onDragOver={handleDragOver}
                    onDrop={dragData =>
                      handleDrop(dragData, 'dragContent', swapBoxes)
                    }
                    name="release-management"
                  />
                  <IconButton
                    className="image-box-close"
                    onClick={() => handleImageDelete(index)}
                    data-testid="close"
                    aria-label="release-management-image-box-delete"
                  >
                    <CloseIcon />
                  </IconButton>
                </Fragment>
              );
            })}
        </div>
        <div className="footer">
          <Button
            autoFocus
            onClick={handleClose}
            className="btn-cancel"
            aria-label="release-management-cancel"
          >
            {Constants.CANCEL}
          </Button>
          <Button
            type="button"
            onClick={handleSave}
            className="btn-save"
            disabled={!releaseName}
            aria-label="release-management-save"
          >
            {Constants.SAVE}
          </Button>
        </div>
      </form>
    </div>
  );

  return (
    <Fragment>
      <div>{body}</div>
      {preview && (
        <NewReleaseDialog
          previewImages={previewImages}
          closeNewReleaseDialog={closeNewReleaseDialog}
        />
      )}
    </Fragment>
  );
};

ReleaseManagement.propTypes = {
  handleClose: PropTypes.func.isRequired
};

export default ReleaseManagement;
