/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S.
 * and Foreign Patents, patent applications, and are protected by trade secret
 * or copyright law. Dissemination of this information, reproduction of this
 * material, and copying or distribution of this software is strictly forbidden
 * unless prior written permission is obtained from Pearson Education, Inc.
 */

export {
  urlModifier,
  parseURLParams,
  logout,
  redirectToExternalLink,
  loadUrlInBlankTab,
  loadUrlInNewTab,
  isSameRequest,
  getRandomNumber,
  getRandomUniqueNumbers,
  getProjectCardBackgroundColor,
  checkTrackChangesEnableDisableStatus,
  getProjectDetailUrl,
  changeUrl,
  getFormattedDate,
  sortByProp,
  sortByPropAndDirection,
  isEmail,
  includes,
  getUserAvatar,
  convertImageIntoBase64,
  copyToClipboard,
  timeStampdateFormatter,
  parseDateTime,
  copyDivToClipboard,
  getLabelByValue,
  getQueryParamValue,
  getDistributionPageUrl,
  downloadFile
} from './utils';
export {
  deleteCookieByName,
  saveCookie,
  loadCookie,
  deleteCookie
} from './cookies';
export {
  postHeaders,
  putHeaders,
  getHeaders,
  deleteHeaders,
  patchHeaders
} from './httpUtils';
export { sendGTMDataLayer } from './gtmDataLayer';
