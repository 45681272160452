/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S.
 * and Foreign Patents, patent applications, and are protected by trade secret
 * or copyright law. Dissemination of this information, reproduction of this
 * material, and copying or distribution of this software is strictly forbidden
 * unless prior written permission is obtained from Pearson Education, Inc.
 */

import React, { useState, useEffect } from 'react';
import { observer, inject } from 'mobx-react';
import { hot } from 'react-hot-loader/root';
import { Tabs, Tab, IconButton } from '@material-ui/core';
import BuildIcon from '@material-ui/icons/Build';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import {
  CustomDialog,
  DialogHeader,
  DialogContent
} from '../../components/common/Dialog';
import Constants from '../../constants';
import featureConstants from '../../constants/featureConstants';
import LobMetaManager from './LobMetaManager';
import CypressRulesAndWorkFlow from './CypressRulesAndWorkFlow';
import { getAllLobMeta } from '../../services/lobMeta';
import './LobManagerTabs.style.scss';

const LobManagerTabs = props => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);

  const {
    store: {
      LineOfBusiness: { getLineOfBusinessList, lobList },
      CypressRules: { getCypressRulesForLob, cypressRulesForLob },
      LobWorkflow: { getWorkFlowRolesForLob, workFlowRolesForLob },
      LobMeta: { setLobMeta },
      FeatureManager: { isFeatureEnabled }
      // ThemeData: { themeList, setThemeList, getThemesForLob, themesForLob }
    }
  } = props;

  const shouldDisplayWorkFlowRoles = isFeatureEnabled(
    featureConstants.CYPRESS_WORKFLOW_ROLES
  );

  useEffect(() => {
    const fetchLobManagerList = async () => {
      if (!openDialog) {
        return;
      }
      // if (!themeList.length) {
      //   const themesList = await getThemeList();
      //   setThemeList(themesList);
      // }
      const response = await Promise.all([
        getAllLobMeta(),
        !lobList.length && getLineOfBusinessList(),
        getCypressRulesForLob(),
        shouldDisplayWorkFlowRoles && getWorkFlowRolesForLob()
        /* Theme Tab would be enabled in future release */
        // !themesForLob.length && getThemesForLob()
      ]);
      setLobMeta(response[0]);
    };
    fetchLobManagerList();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openDialog]);

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const openLobManagerDialog = () => {
    setOpenDialog(true);
  };

  const closeLobManagerDialog = () => {
    setSelectedTab(0);
    setOpenDialog(false);
  };

  const renderTabModules = () => {
    if (selectedTab === 2 || selectedTab === 3) {
      return (
        <LobMetaManager
          type={
            selectedTab === 2
              ? Constants.LOB_META_TYPE_METADATA
              : Constants.LOB_META_TYPE_METALINK
          }
          closeDialog={closeLobManagerDialog}
        />
      );
    }
    // if (selectedTab === 3) {
    //   return <Themes />;
    // }
    // According to the tab selected, checking value for rules to display
    const rulesForSelectedTab =
      selectedTab === 1 ? workFlowRolesForLob : cypressRulesForLob;
    return (
      !!rulesForSelectedTab.length && (
        <CypressRulesAndWorkFlow
          closeLobManagerDialog={closeLobManagerDialog}
          selectedTab={selectedTab}
        />
      )
    );
  };

  const body = (
    <div>
      <div className="header">
        <Tabs value={selectedTab} onChange={handleChange}>
          <Tab data-testid="cypress-rules" label={Constants.CYPRESS_RULES} />
          {shouldDisplayWorkFlowRoles && (
            <Tab
              data-testid="workflow-roles"
              label={Constants.WORK_FLOW_ROLES}
            />
          )}
          <Tab
            data-testid="project-metadata"
            label={Constants.PROJECT_METADATA}
          />
          <Tab
            data-testid="project-metalink"
            label={Constants.PROJECT_METALINK}
          />

          {/*  <Tab data-testid="themes" label={Constants.THEMES} /> */}
        </Tabs>
        <IconButton
          aria-label="close"
          className="close-button"
          onClick={closeLobManagerDialog}
          data-testid="closeIcon"
        >
          <CloseIcon />
        </IconButton>
      </div>
      {renderTabModules()}
    </div>
  );
  return (
    <>
      <div className="lob-manager-wrapper">
        <button
          className="lob-manager-btn"
          type="button"
          onClick={openLobManagerDialog}
          data-testid="lob-manager-btn"
        >
          <BuildIcon />
          {Constants.LOB_MANAGER}
        </button>
      </div>
      <CustomDialog
        onClose={closeLobManagerDialog}
        open={openDialog}
        className="lob-manager-dialog"
        maxWidth={false}
      >
        <DialogHeader
          onClose={closeLobManagerDialog}
          title={Constants.LOB_MANAGER}
        />
        <DialogContent
          className="lob-manager-content"
          data-testid="lobWorkFlowDialogContent"
        >
          {body}
        </DialogContent>
      </CustomDialog>
    </>
  );
};

LobManagerTabs.propTypes = {
  store: PropTypes.shape({
    LineOfBusiness: PropTypes.shape({
      lobList: PropTypes.arrayOf(PropTypes.object),
      getLineOfBusinessList: PropTypes.func
    }),
    // ThemeData: PropTypes.shape({
    //   themeList: PropTypes.arrayOf(PropTypes.object),
    //   setThemeList: PropTypes.func,
    //   getThemesForLob: PropTypes.func,
    //   themesForLob: PropTypes.arrayOf(PropTypes.object)
    // }),
    CypressRules: PropTypes.shape({
      getCypressRulesForLob: PropTypes.func,
      cypressRulesForLob: PropTypes.arrayOf(PropTypes.object)
    }),
    LobWorkflow: PropTypes.shape({
      workFlowRolesForLob: PropTypes.arrayOf(PropTypes.object),
      getWorkFlowRolesForLob: PropTypes.arrayOf(PropTypes.object)
    }),
    FeatureManager: PropTypes.shape({
      isFeatureEnabled: PropTypes.func.isRequired
    }),
    LobMeta: PropTypes.shape({
      setLobMeta: PropTypes.func.isRequired
    })
  })
};

export default hot(inject('store')(observer(LobManagerTabs)));
