/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S.
 * and Foreign Patents, patent applications, and are protected by trade secret
 * or copyright law. Dissemination of this information, reproduction of this
 * material, and copying or distribution of this software is strictly forbidden
 * unless prior written permission is obtained from Pearson Education, Inc.
 */

import React, { PureComponent } from 'react';
import { load, remove } from 'react-cookies';
import Constants from '../../constants';
import { redirectToLogin } from '../../utils/utils';

export const withAuth = AppComponent => {
  return class extends PureComponent {
    constructor(props) {
      super(props);
      this.state = {
        loadApp: false
      };
    }

    componentDidMount() {
      this.checkAuth();
    }

    // eslint-disable-next-line consistent-return
    checkAuth = () => {
      const token = load(Constants.AZURE_COOKIE_NAME);
      // eslint-disable-next-line no-console
      console.log('withAuth:myCloudProxySession:', token);
      if (!token) {
        // deleting User Info if session expired or azure token cookie not found
        remove(Constants.USER_INFO);
        return redirectToLogin(window.location.href);
      }
      this.setState({
        loadApp: true
      });
    };

    render() {
      const { loadApp } = this.state;
      if (loadApp) {
        return <AppComponent {...this.props} />;
      }
      return null;
    }
  };
};
