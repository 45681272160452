/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S.
 * and Foreign Patents, patent applications, and are protected by trade secret
 * or copyright law. Dissemination of this information, reproduction of this
 * material, and copying or distribution of this software is strictly forbidden
 * unless prior written permission is obtained from Pearson Education, Inc.
 */

import React, { useState } from 'react';
import TuneIcon from '@material-ui/icons/Tune';
import PreferencesDialog from './PreferencesDialog';
import Constants from '../../constants';

const Preferences = () => {
  const [openDialog, setOpenDialog] = useState(false);

  const openPreferenceDialog = () => {
    setOpenDialog(true);
  };

  const closePreferenceDialog = () => {
    setOpenDialog(false);
  };
  return (
    <>
      <button
        className="preferences"
        type="button"
        title={Constants.PREFERENCES_TOOLTIP}
        data-testid="preferences-menu-btn"
        onClick={openPreferenceDialog}
      >
        <TuneIcon />
        {Constants.PREFERENCES}
      </button>
      <PreferencesDialog isOpen={openDialog} close={closePreferenceDialog} />
    </>
  );
};
export default Preferences;
