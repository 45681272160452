/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S.
 * and Foreign Patents, patent applications, and are protected by trade secret
 * or copyright law. Dissemination of this information, reproduction of this
 * material, and copying or distribution of this software is strictly forbidden
 * unless prior written permission is obtained from Pearson Education, Inc.
 */

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Typography, Fab, Badge } from '@material-ui/core';
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone';
import { observer, inject } from 'mobx-react';
import { hot } from 'react-hot-loader/root';

import { getUserAvatar } from '../../utils';

import './UserDetailBtn.styles.scss';

const UserDetailBtn = props => {
  const {
    store: {
      NotificationDrawer: {
        openNotificationDrawer,
        hasNotificationAlert,
        checkNotificationAlert
      },
      UserInfo: { firstName, email, isAuthorized }
    }
  } = props;

  useEffect(() => {
    async function fetchData() {
      await checkNotificationAlert();
    }
    if (!hasNotificationAlert) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const firstCharOfUserName = firstName ? firstName[0].toUpperCase() : '';

  if (!isAuthorized) {
    return null;
  }

  return (
    <Badge
      className="user-detail-badge"
      color="secondary"
      overlap="circle"
      badgeContent={<NotificationsNoneIcon fontSize="small" />}
      invisible={!hasNotificationAlert}
    >
      <Fab
        variant="extended"
        color="primary"
        aria-label="user-details-menu"
        aria-haspopup="true"
        onClick={openNotificationDrawer}
        className="muiFab-login"
        data-testid="user-details-menu"
      >
        <Typography
          variant="h6"
          component="label"
          color="primary"
          className="muiTypography-login"
        >
          {firstCharOfUserName}
        </Typography>
        <Typography
          component="span"
          className="user-profile-img"
          style={getUserAvatar(email)}
        />
      </Fab>
    </Badge>
  );
};

UserDetailBtn.propTypes = {
  store: PropTypes.shape({
    UserInfo: PropTypes.shape({
      firstName: PropTypes.string.isRequired,
      fullName: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      isAuthorized: PropTypes.bool.isRequired,
      userId: PropTypes.string.isRequired
    }),
    NotificationDrawer: PropTypes.shape({
      openNotificationDrawer: PropTypes.func.isRequired,
      hasNotificationAlert: PropTypes.bool.isRequired,
      fetchResources: PropTypes.func.isRequired,
      checkNotificationAlert: PropTypes.func.isRequired
    })
  })
};

export default hot(inject('store')(observer(UserDetailBtn)));
