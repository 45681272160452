/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S.
 * and Foreign Patents, patent applications, and are protected by trade secret
 * or copyright law. Dissemination of this information, reproduction of this
 * material, and copying or distribution of this software is strictly forbidden
 * unless prior written permission is obtained from Pearson Education, Inc.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { observer, inject } from 'mobx-react';
import { hot } from 'react-hot-loader/root';

import './ProjectTitle.style.scss';

const ProjectTitle = props => {
  const {
    store: {
      Header: { title, subTitle }
    }
  } = props;

  return (
    <Typography
      variant="h6"
      component="label"
      color="secondary"
      className="project-title"
      title={title}
    >
      {title} <span className="project-sub-title">{subTitle}</span>
    </Typography>
  );
};

ProjectTitle.propTypes = {
  store: PropTypes.shape({
    Header: PropTypes.shape({
      title: PropTypes.string.isRequired,
      subTitle: PropTypes.string.isRequired,
      version: PropTypes.string.isRequired
    }),
    Config: PropTypes.shape({
      RELEASE_NUMBER: PropTypes.string.isRequired
    })
  })
};

export default hot(inject('store')(observer(ProjectTitle)));
