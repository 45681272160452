/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S.
 * and Foreign Patents, patent applications, and are protected by trade secret
 * or copyright law. Dissemination of this information, reproduction of this
 * material, and copying or distribution of this software is strictly forbidden
 * unless prior written permission is obtained from Pearson Education, Inc.
 */

import axios from 'axios';
import Bottleneck from 'bottleneck/es5';
import qs from 'qs';
import cookie from 'react-cookies';
import merge from 'lodash.merge';
import uuidv4 from 'uuid/v4';
import Contants from '../../constants';

/**
 * USAGE:
 * ------------------------------
 * Here is the example, how this file can be used to make an API call
 *
 * MANDATORY PARAMETERS:
 * --------------------------
 * @param {*} requestOptions - Configuration for axios call
 * @param {string} path - relative path for the end point
 *
 * const path = `/api/v2`
 * const requestOptions = {
 *    method: 'POST',
 *    // Additional headers
 *    headers: { 'Content-Type': 'application/json' },
 *    body: JSON.stringify({ label })
 * }
 *
 * OPTIONAL PARAMETERS
 * -----------------------------------------
 * @param {*} params - Query string (in object form) to append in the url
 *
 * const params = { page, pagesize: PAGE_SIZE }
 * const errorConfig = {
 *      skipError: true,  // whether we want to skip the error handling or not
 *      codes: [HttpStatus.NOT_FOUND] // which error code should not be considered as error for displaying the error message
 *  }
 *
 * ACTUAL CALL:
 * -------------------
 * const response = await axiosFetch(requestOptions, path, params, errorConfig)
 */

const httpLimiter = new Bottleneck({
  maxConcurrent: 10,
  reservoir: 100,
  reservoirRefreshAmount: 100,
  reservoirRefreshInterval: 30 * 1000
});

// This limiter would be used for processing services required to run in background
const progressiveLimiter = new Bottleneck({
  maxConcurrent: 10,
  reservoir: 100,
  reservoirRefreshAmount: 100,
  reservoirRefreshInterval: 30 * 1000
});

// Setting default headers
const generateGenericRequestOptions = () => ({
  method: 'GET',
  headers: {
    Accept: 'application/json'
  }
});

/**
 * Checking whether global error handler is enabled or disabled
 * @param {*} params
 */
const shouldSkipErrorHandling = (errorCode, props) => {
  const { skipError, codes, excludedCodes } = props;

  /* istanbul ignore else  */
  if (skipError && codes.length > 0) {
    return codes.includes(errorCode);
  }
  if (skipError && Array.isArray(excludedCodes) && excludedCodes.length > 0) {
    // When we want to ignore all status except some status like 401
    return !excludedCodes.includes(errorCode);
  }
  return skipError;
};

async function executeFetch(
  requestOptions,
  path,
  params,
  errorHandlingProps = { skipError: false, codes: [] }
) {
  const pearsonUrlWithEnv = `${path}?${qs.stringify(params, {
    indices: false
  })}`;

  let headers = {};
  const userName = cookie.load('USER_NAME');
  const azureToken = cookie.load(Contants.AZURE_COOKIE_NAME);
  headers = {
    'x-prsn-user-id': userName,
    myCloudProxySession: azureToken,
    'x-correlation-id': uuidv4()
  };

  // destructuring requestOptions to use the options object which doesn't have body property
  const { body: data, ...options } = requestOptions;
  // method that defined which codes are valid and which are not
  const validateStatus = status => {
    // default, pass all 2xx
    /* istanbul ignore else  */
    if (status >= 200 && status < 300) {
      return true;
    }
    return shouldSkipErrorHandling(status, errorHandlingProps);
  };
  const defaultConfig = { backgroundProcess: false };

  const requestOptionsWithAuth = merge(
    { url: pearsonUrlWithEnv },
    generateGenericRequestOptions(),
    { config: defaultConfig },
    options,
    { data },
    { headers },
    { validateStatus }
  );
  let result;
  // if backgroundProcess property is true send to limiter else send to httpLimiter
  if (requestOptions.config && requestOptions.config.backgroundProcess) {
    result = await progressiveLimiter.schedule(() =>
      axios(requestOptionsWithAuth)
    );
  } else {
    result = await httpLimiter.schedule(() => axios(requestOptionsWithAuth));
  }
  return result;
}

/**
 * Highest-level decorator for data ProjectAPI requests. Deals with authentication, builds
 * the domain and sends the request.
 * @param {*} requestOptions
 * @param {string} path
 * @param {*} params
 */
export function axiosFetch(requestOptions, path, params, errorHandlingProps) {
  return executeFetch(requestOptions, path, params, errorHandlingProps);
}

/**
 * Returns the cancel source, to cancel the previous call
 *
 * usage:
 * const cancelSource = getCancelSource()
 * axios.get(url, { cancelToken: cancelSource.token })
 * cancelSource.cancel()
 */
export function getCancelSource() {
  const { CancelToken } = axios;
  return CancelToken.source();
}

/**
 * Returns the counter for the parallel jobs on the page.
 */
export function counts() {
  return httpLimiter.counts();
}
