/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S.
 * and Foreign Patents, patent applications, and are protected by trade secret
 * or copyright law. Dissemination of this information, reproduction of this
 * material, and copying or distribution of this software is strictly forbidden
 * unless prior written permission is obtained from Pearson Education, Inc.
 */

/* eslint-disable no-param-reassign */
import { types } from 'mobx-state-tree';
import { toJS } from 'mobx';
import differenceBy from 'lodash/differenceBy';
import Project from './Project';
import {
  getProjects,
  getEntityUrnsOrProductIdsBySearchParam
} from '../services/getProjects';
import {
  isSameRequest,
  getProjectCardBackgroundColor,
  sortByPropAndDirection
} from '../utils';
import { getPublishStatusByDistributableList } from '../services/getPublishStatusByDistributableList';
import { getPublishedLiveVersionsByProductId } from '../services/getPublishedLiveVersionsByProductId';
import TableModeConstants from '../constants/tableModeConstants';
import {
  getDistributionText,
  getDisplayStatusText
} from '../components/project-version/ProjectVersionUtil';
import { DISTRIBUTABLE_URN_REGEX } from '../utils/regexPatterns';

let lastAPIParams = {};
let entityUrnsOrProductIds = {};

export default types
  .model('ProjectList', {
    projects: types.array(Project),
    pageIndex: 1,
    currentPageIndex: 0,
    hasMoreResults: true,
    projectPublishStatus: types.frozen({}),
    totalProjects: 0,
    loadProjectsCount: 0
  })
  .views(self => ({
    isProjectExist(id) {
      return self.projects.find(project => project.id === id) || {};
    },
    isProjectPublishStatusExist(id) {
      return self.projectPublishStatus[id];
    }
  }))
  .actions(self => ({
    resetProjects() {
      self.projects = [];
      self.totalProjects = 0;
    },

    resetPageIndex() {
      self.pageIndex = 1;
      self.currentPageIndex = 0;
    },

    resetLastAPIParams() {
      lastAPIParams = {};
    },

    setHasMoreResults(hasMoreResults) {
      self.hasMoreResults = hasMoreResults;
    },

    resetAllProjectsData(isLoadingRequired = true) {
      self.resetLastAPIParams();
      self.resetProjects();
      self.resetPageIndex();
      if (!isLoadingRequired) {
        self.setHasMoreResults(false);
      } else {
        self.setHasMoreResults(true);
      }
    },

    // Fetching live status of projects using productIds
    updatePublishedLiveStatus(productIdsToFetchLiveStatus) {
      if (productIdsToFetchLiveStatus.length) {
        // If there is atleast one productId, sending productIds array to getPublishedLiveVersionsByProductId service
        getPublishedLiveVersionsByProductId(productIdsToFetchLiveStatus).then(
          res => {
            if (Object.keys(res).length) {
              // Finding projects with productIds received in response and setting hasLiveVersion to true
              Object.keys(res).forEach(productId => {
                const objProject = self.projects.find(
                  project => project.productId === productId
                );
                objProject.setHasLiveVersion(true);
              });
            }
          }
        );
      }
    },

    /**
     * Projects are fetched from Project API based on Query Params and Page index.
     * delete previous projects if resetProjects is true.
     * @param {*} params
     * @param {bool} resetProjects
     * @param {bool} useLastPageIndex - If true, will use last page index.
     */

    async loadProjects(
      queryParams,
      resetProjects,
      useLastPageIndex,
      shouldFetchPublishStatus,
      tableModeSortAttr,
      requestProjectAccessMode
    ) {
      const appliedPageIndex = useLastPageIndex
        ? self.currentPageIndex
        : self.pageIndex;

      // fetching values for table mode sort
      const { sortAttribute = null, sortOrder = null, serverSideSort = false } =
        tableModeSortAttr || {};

      let formatQueryParams = { ...queryParams };
      if (requestProjectAccessMode) {
        formatQueryParams = { ...formatQueryParams, viewMode: 'table' };
      }
      const apiParams = {
        page: resetProjects ? 1 : appliedPageIndex,
        ...(serverSideSort && { sortAttribute, sortOrder }),
        ...formatQueryParams
      };

      // sanity block duplicate calls except when toggling off favorite icon with favorite filter selected.
      if (isSameRequest(lastAPIParams, apiParams) && !useLastPageIndex) {
        return;
      }

      lastAPIParams = apiParams;

      // fetch data from C6 or CSG, If status or platform is selected (and searchTerm is not a Durn) and request is for first page
      // We inject that data for all subsequent pages
      const shouldFetchDataFromC6OrCSG =
        apiParams.page === 1 &&
        (apiParams.projectPublishPlatform !== 'default' ||
          apiParams.projectPublishStatus !== 'default');

      const isSearchtermDUrn =
        apiParams.searchTerm &&
        apiParams.searchTerm.match(DISTRIBUTABLE_URN_REGEX);

      const isStatusOrPlatformNotSelected =
        apiParams.projectPublishPlatform === 'default' &&
        apiParams.projectPublishStatus === 'default';

      // TODO: cleanup below code once new search api changes are stable
      // pass #/?useOldSearchAPI=true in url to compare results with old search api
      apiParams.useOldSearchAPI =
        window.location.hash === '#/?useOldSearchAPI=true';

      if (
        shouldFetchDataFromC6OrCSG &&
        !apiParams.useOldSearchAPI &&
        !isSearchtermDUrn
      ) {
        entityUrnsOrProductIds = await getEntityUrnsOrProductIdsBySearchParam(
          apiParams,
          useLastPageIndex,
          requestProjectAccessMode
        );
      }

      // if status or platform not selected then no need to inject any data in request.
      if (isStatusOrPlatformNotSelected || isSearchtermDUrn) {
        entityUrnsOrProductIds = null;
      }

      // passing useLastPageIndex to skip canceling the load projects call
      const {
        projects = [],
        hasMore = false,
        totalResults = 0
      } = await getProjects(
        apiParams,
        useLastPageIndex,
        requestProjectAccessMode,
        entityUrnsOrProductIds
      );

      self.setTotalProjectCount(totalResults);

      self.setHasMoreResults(hasMore);
      // if there is a new search term, we have to reset the existing state of projects Array
      if (resetProjects) {
        self.resetPageIndex();
        self.resetProjects();
      }
      let projectList = projects;
      if (useLastPageIndex) {
        // Only passing new projects which is coming in API response and not already available in project model.
        projectList = differenceBy(projects, toJS(self.projects), 'id');
      }

      const hasPublishStatus = !shouldFetchPublishStatus;
      self.updateProjects(projectList, hasPublishStatus);
      self.incrementLoadProjectsCount();

      // Table mode client side sort
      if (!serverSideSort && sortAttribute && sortOrder) {
        self.sortProjectsAtClientSide(sortAttribute, sortOrder);
      }

      // if the API returns hasMore result except when toggling off favorite icon with favorite filter selected
      if (hasMore && !useLastPageIndex) {
        self.incrementPageIndex();
      }

      if (shouldFetchPublishStatus && projectList.length > 0) {
        // For Grid View, send all productIds since publishInfo not available
        const productIdsToFetchLiveStatus = [];
        projectList.forEach(projectItem => {
          if (projectItem.productId) {
            productIdsToFetchLiveStatus.push(projectItem.productId);
          }
        });
        self.getDistributablesPublishStatus(projectList);
        self.updatePublishedLiveStatus(productIdsToFetchLiveStatus);
      }

      if (!shouldFetchPublishStatus) {
        // If publishInfo available, send product Ids with platforms other than GPS and Virtual schools
        const productIdsToFetchLiveStatus = [];
        projectList.forEach(projectItem => {
          const { publishInfo, status, productId } = projectItem;
          if (
            publishInfo &&
            productId &&
            status.publishStatus !== 'PUBLISHED-LIVE' &&
            publishInfo.publishDestination !== 'PCX' &&
            publishInfo.publishDestination !== 'GPS'
          ) {
            productIdsToFetchLiveStatus.push(projectItem.productId);
          }
        });

        self.updatePublishedLiveStatus(productIdsToFetchLiveStatus);
      }
    },

    /**
     * Update Projects in the state
     * @param {*} projects
     * @param {bool} isPublishStatusLoaded
     */
    updateProjects(projects, hasPublishStatus = true) {
      projects.forEach(project => {
        self.addProject(project, hasPublishStatus);
      });
    },

    /**
     * If there are more results in the API, increment the page index (used for react-infinite-scroller) and current page index.
     */
    incrementPageIndex() {
      self.pageIndex += 1;
      self.currentPageIndex += 1;
    },

    addProject(project, hasPublishStatus) {
      const { backgroundColor } = getProjectCardBackgroundColor();
      const {
        id,
        name,
        authorName,
        thumbnail,
        entityUrn,
        bookCover,
        status = {},
        alfresco,
        alfrescoEpubDetail,
        tcm,
        gridId,
        productId,
        isLocked,
        permissions,
        userCount,
        dateCreated,
        publishInfo,
        userApprover,
        userApproverFirstName,
        lineOfBusiness,
        sharingContextRole,
        isEpubProject,
        isTccProject,
        isProjectCancelled,
        origin,
        roleId,
        isAddUserPermissionExist,
        importantNotes: projectNotes,
        quadFolder
      } = project;

      const { projectStatus = '', publishStatus = null } = status;

      // Adding extra props for sorting
      const statusDisplayText = getDisplayStatusText({
        projectStatus: projectStatus.toUpperCase(),
        publishStatus
      });

      const distributionInfo = getDistributionText(publishInfo);
      const distributionDisplayText = distributionInfo.distributionText;
      const submittedOnDisplayText = distributionInfo.transactionDate || ' ';

      self.projects.push({
        id,
        entityUrn,
        name,
        authorName,
        thumbnail,
        backgroundColor,
        // We don't get isFavorite property for loadProjects endpoint
        alfresco,
        alfrescoEpubDetail,
        bookCover,
        isLocked,
        publishStatus,
        distributionDisplayText,
        submittedOnDisplayText,
        gridId,
        productId,
        userCount,
        dateCreated,
        publishInfo,
        userApprover,
        userApproverFirstName,
        lineOfBusiness,
        sharingContextRole,
        isEpubProject,
        isTccProject,
        isProjectCancelled,
        origin,
        projectNotes,
        quadFolder,
        isFavourite: false,
        tcm: tcm || {},
        permissions: permissions || [],
        status: projectStatus.toLowerCase(),
        statusDisplayText: hasPublishStatus ? statusDisplayText : null,
        publishDestination:
          publishInfo && publishInfo.publishDestination
            ? publishInfo.publishDestination
            : null,
        roleId: roleId || null,
        isAddUserPermissionExist: isAddUserPermissionExist || null,
        hasLiveVersion: publishStatus === 'PUBLISHED-LIVE'
      });
    },

    /**
     * Called when we un-favorite the project after selecting favorite filter.
     * @param - selectedEntityUrn: Entity urn of selected project
     */
    removeProject(selectedEntityUrn, getQueryParams) {
      // Finding index of selected project from project list model.
      const index = self.projects.findIndex(
        item => item.entityUrn === selectedEntityUrn
      );
      // Remove project from project list model, on which favorite was toggled off
      if (index !== -1) {
        self.projects.splice(index, 1);
      }
      // if total project count <= currentcount , hasMore= false

      if (self.totalProjects > 0) {
        self.totalProjects -= 1;
        if (self.totalProjects <= self.projects.length) {
          self.hasMoreResults = false;
        }
        if (self.hasMoreResults) {
          // if more projects exist, API call will happen to fetch next project for current page index
          self.loadProjects(getQueryParams, false, true);
        }
      }
    },

    updateUserCountByEntity(entityUrn, userCount) {
      // update count
      const objProject = self.projects.find(
        project => project.entityUrn === entityUrn
      );
      if (objProject) {
        objProject.updateUserCount(userCount);
      }
    },

    appendUserCountByEntity(entityUrn, userCount) {
      // update count
      const objProject = self.projects.find(
        project => project.entityUrn === entityUrn
      );
      if (objProject) {
        objProject.updateUserCount(objProject.userCount + userCount);
      }
    },

    async getDistributablesPublishStatus(projectList) {
      const distributableList = [];
      const publishStatusObj = {};

      projectList.forEach(project => {
        distributableList.push({
          currentVersionDURN: project.id,
          previousVersionDURN: project.previousDistributableUrn || ''
        });
      });

      // Fetch the publishStatus of Distributables
      const publishStatusResult = await getPublishStatusByDistributableList(
        distributableList
      );

      // creating key value pair of dURN and its status
      publishStatusResult.forEach(publish => {
        const { distributableUrn, publishInfo, publishStatus } = publish;
        if (publishInfo) {
          publishStatusObj[distributableUrn] = {
            publishInfo,
            publishStatus
          };
        }
      });

      distributableList.forEach(obj => {
        // find project
        const project = self.projects.find(
          projectObj => projectObj.id === obj.currentVersionDURN
        );

        // find publishStatus from result
        const publishStatusOfProject = publishStatusResult.find(
          status => status.distributableUrn === obj.currentVersionDURN
        );
        const publishStatusText = publishStatusOfProject
          ? publishStatusOfProject.publishStatus
          : '';

        // get displayStatusText
        const displayStatusText = getDisplayStatusText({
          projectStatus: project.status.toUpperCase(),
          publishStatus: publishStatusText
        });
        project.updatePublishStatus(publishStatusText, displayStatusText);
        project.updatePublishInfo(publishStatusOfProject);
      });

      // update publish Status in modal
      self.updatePublishStatus(publishStatusObj);
    },

    updatePublishStatus(statusObj) {
      self.projectPublishStatus = {
        ...self.projectPublishStatus,
        ...statusObj
      };
    },
    sortProjectsAtClientSide(columnToSort, sortDirection) {
      const allProjects = [...toJS(self.projects)];

      allProjects.sort(
        sortByPropAndDirection(
          columnToSort,
          sortDirection,
          // Adding boolean for number sort on user count prop
          columnToSort === TableModeConstants.COL_USERS_ID
        )
      );

      self.projects = [];
      allProjects.forEach(project => {
        self.projects.push(project);
      });
    },
    setTotalProjectCount(count) {
      self.totalProjects = count;
    },
    incrementLoadProjectsCount() {
      self.loadProjectsCount += 1;
    }
  }));
