/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S.
 * and Foreign Patents, patent applications, and are protected by trade secret
 * or copyright law. Dissemination of this information, reproduction of this
 * material, and copying or distribution of this software is strictly forbidden
 * unless prior written permission is obtained from Pearson Education, Inc.
 */

/* eslint-disable no-param-reassign */
import { types } from 'mobx-state-tree';
import { getAllLobMeta } from '../services/lobMeta';

export const LobMetaItem = types.model('LobMetaItem', {
  lob: types.string,
  metalink: types.array(types.string),
  metadata: types.array(types.string)
});

export default types
  .model('LobMeta', {
    lobMetaList: types.array(LobMetaItem)
  })
  .views(self => ({
    isMetalinkEnabled(lobId, metalinkId) {
      if (!lobId) {
        return true;
      }
      const matchedLob = self.lobMetaList.find(
        metaItem => metaItem.lob === lobId
      );
      // metalink is enabled by default
      return !matchedLob || matchedLob.metalink.includes(metalinkId);
    },
    isMetaDataEnabled(lobId, metadataId) {
      if (!lobId) {
        return false;
      }
      const matchedLob = self.lobMetaList.find(
        metaItem => metaItem.lob === lobId
      );
      // metadata is disabled  by default
      return matchedLob && matchedLob.metadata.includes(metadataId);
    }
  }))
  .actions(self => ({
    setLobMeta(list) {
      self.lobMetaList = list;
    },
    async getLobMeta() {
      const data = await getAllLobMeta();
      self.setLobMeta(data);
    },
    updateSingleLobMeta(type, lob, data) {
      const matchedData = self.lobMetaList.find(
        metaItem => metaItem.lob === lob
      );
      if (matchedData) {
        matchedData[type] = data[type];
      } else {
        // if data for lob is not found then create a new data
        self.lobMetaList.push({
          lob,
          metadata: data.metadata,
          metalink: data.metalink
        });
      }
    }
  }));
