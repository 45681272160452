/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S.
 * and Foreign Patents, patent applications, and are protected by trade secret
 * or copyright law. Dissemination of this information, reproduction of this
 * material, and copying or distribution of this software is strictly forbidden
 * unless prior written permission is obtained from Pearson Education, Inc.
 */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Tooltip
} from '@material-ui/core';
import { getFileUrlsLogs } from '../../services/getFileUrlsLogs';
import { getFormattedDate } from '../../utils';
import CustomInifiniteScroll from '../custom-infinite-scroll/CustomInfiniteScroll';
import './FileUrlsActivity.style.scss';
import Constants from '../../constants';

let lastPageIndex = -1;

const FileUrlsActivity = ({ forceUpdate }) => {
  const [fileUrlsRow, setFileUrlsRow] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [pageIndex, setPageIndex] = useState(0);

  useEffect(() => {
    setPageIndex(0);
    setHasMore(true);
    setFileUrlsRow([]);
    lastPageIndex = -1;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forceUpdate]);

  const loadMoreProjects = async () => {
    if (lastPageIndex === pageIndex - 1) {
      const data = await getFileUrlsLogs(pageIndex + 1);
      setHasMore(data.hasMore);
      setPageIndex(pageIndex + 1);
      setFileUrlsRow(prev => [...prev, ...data.transactions]);
      lastPageIndex += 1;
    }
  };

  const projectActivityColumns = [
    {
      id: 'key',
      label: 'File Key',
      classes: 'fileurl-activity__table-head--table-heading-key',
      'data-testid': 'fileKey'
    },
    {
      id: 'value',
      label: 'File Url',
      classes: 'fileurl-activity__table-head--table-heading-value',
      'data-testid': 'fileValue'
    },
    {
      id: 'updatedBy',
      label: 'Updated By',
      classes: 'fileurl-activity__table-head--table-heading-updated-by',
      'data-testid': 'updatedBy'
    },
    {
      id: 'updatedDate',
      label: 'Update Time',
      classes: 'fileurl-activity__table-head--table-heading-updated-date',
      'data-testid': 'updatedTime'
    }
  ];

  /**
   * Returning table header to render in tableHead
   */
  const renderTableHead = () => {
    return projectActivityColumns.map(column => {
      const { id, label, classes, ...otherProps } = column;

      return (
        <TableCell key={id} className={`${classes}`} {...otherProps}>
          {label && (
            <Tooltip title={label}>
              <span>{label}</span>
            </Tooltip>
          )}
        </TableCell>
      );
    });
  };

  /**
   * Returning table cells to render in table body
   */
  const renderTableRows = () => {
    return fileUrlsRow.map((row, index) => {
      const { key, value, updatedBy, updateDate } = row;
      return (
        <TableRow hover key={updateDate} data-testid={`row-${index + 1}`}>
          <TableCell className="fileurl-activity__table-body--key">
            <div title={key}>{key}</div>
          </TableCell>
          <TableCell className="fileurl-activity__table-body--value">
            <div title={value}>{value}</div>
          </TableCell>
          <TableCell className="fileurl-activity__table-body--updated-by">
            <div title={updatedBy}>{updatedBy}</div>
          </TableCell>
          <TableCell className="fileurl-activity__table-body--updated-date">
            <div title={updateDate}>
              {getFormattedDate(updateDate, Constants.DATE_DISPLAY_FORMAT)}
            </div>
          </TableCell>
        </TableRow>
      );
    });
  };

  return (
    <div className="fileurl-activity customized-table-styles">
      <TableContainer
        className="fileurl-activity__table-container"
        id="projectActivityTableContainer"
      >
        <Table stickyHeader className="fileurl-activity__table">
          <TableHead
            className="fileurl-activity__table-head"
            data-testid="projectActivityHead"
          >
            <TableRow>{renderTableHead()}</TableRow>
          </TableHead>
          <CustomInifiniteScroll
            loadMore={loadMoreProjects}
            hasMore={hasMore}
            useWindow={false}
            element="tbody"
            getScrollParent={() =>
              document.getElementById('projectActivityTableContainer')
            }
            threshold={10}
            pageIndex={pageIndex}
          >
            {renderTableRows()}
          </CustomInifiniteScroll>
        </Table>
      </TableContainer>
    </div>
  );
};

FileUrlsActivity.propTypes = {
  forceUpdate: PropTypes.number.isRequired
};
export default FileUrlsActivity;
