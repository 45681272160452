/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S.
 * and Foreign Patents, patent applications, and are protected by trade secret
 * or copyright law. Dissemination of this information, reproduction of this
 * material, and copying or distribution of this software is strictly forbidden
 * unless prior written permission is obtained from Pearson Education, Inc.
 */

import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import constants from '../../../constants';
import CaraouselContent from './CarouselContent';
import Slide from './Slide';
import Arrow from './Arrow';
import Dots from './Dots';
import './Carousel.style.scss';

/**
 * Root component for Caraousel. It renders the SliderContent component
 * @param {*} props
 */
const Carousel = props => {
  const { slides: allSlides, name = '' } = props;

  const firstSlide = allSlides[0] || {};
  const secondSlide = allSlides[1] || {};
  const lastSlide = allSlides[allSlides.length - 1] || {};

  // Slider component DOM element reference
  const carouselRef = useRef(null);
  const transitionRef = useRef();

  // Width of the slider component
  const [carouselWidth, setCarouselWidth] = useState(0);
  const [showSlides, setShowSlides] = useState(false);

  // in seconds
  const TRANSITION_TIME = 0.45;

  // translate and transition values, which are used for transition animation
  // eslint-disable-next-line no-unused-vars
  const [state, setState] = useState({
    // this slide index is based on allSlides array
    activeSlideIndex: 0,
    translate: 0,
    transition: TRANSITION_TIME,
    // this array will contain only 3 active images
    activeSlides: [lastSlide, firstSlide, secondSlide]
  });

  const { activeSlideIndex, translate, transition, activeSlides } = state;

  /**
   * Updating activeSlides array and keeping only 3 images in the array
   * at a given time. Array is updating based on user interaction
   */
  const updateActiveSlides = () => {
    let updatedSlides = [];

    if (activeSlideIndex === allSlides.length - 1) {
      // when last slide active
      updatedSlides = [allSlides[allSlides.length - 2], lastSlide, firstSlide];
    } else if (activeSlideIndex === 0) {
      // when first slide active
      updatedSlides = [lastSlide, firstSlide, secondSlide];
    } else {
      updatedSlides = allSlides.slice(
        activeSlideIndex - 1,
        activeSlideIndex + 2
      );
    }

    setState({
      ...state,
      activeSlides: updatedSlides,
      // temporarily stopping transition from running
      transition: 0,
      translate: carouselWidth
    });
  };

  // Executing useEffect for componentDidMount and componentDidUpdate
  // and setting the reference of updateActiveSlides function
  useEffect(() => {
    transitionRef.current = updateActiveSlides;
  });

  // Executing useEffect only for componentDidMount
  // Fetching the carousel width in pixels, although we are setting
  // the width in percent through css
  useEffect(() => {
    if (carouselRef.current && carouselWidth === 0) {
      setCarouselWidth(carouselRef.current.offsetWidth);
      setState({
        ...state,
        translate: carouselRef.current.offsetWidth
      });
      setShowSlides(true);
    }

    // Updating the activeSlides array on transition end event
    const onTransitionEnd = e => {
      if (e.target.className.includes('carousel-content')) {
        transitionRef.current();
      }
    };

    const transitionEnd = window.addEventListener(
      'transitionend',
      onTransitionEnd
    );

    // Cleaning function
    return () => {
      window.removeEventListener('transitionend', transitionEnd);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Setting transition time, if it is set to 0
   */
  useEffect(() => {
    if (transition === 0) {
      setState({
        ...state,
        transition: TRANSITION_TIME
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transition]);

  /**
   * Loading previous slide
   */
  // eslint-disable-next-line consistent-return
  const handlePrevSlideClick = () => {
    setState({
      ...state,
      // setting translate to 0, as previous slide is the first slide hence x = 0
      translate: 0,
      activeSlideIndex:
        activeSlideIndex === 0 ? allSlides.length - 1 : activeSlideIndex - 1
    });
  };

  /**
   * Loading next slide
   */
  // eslint-disable-next-line consistent-return
  const handleNextSlideClick = () => {
    setState({
      ...state,
      translate: translate + carouselWidth,
      activeSlideIndex:
        activeSlideIndex === allSlides.length - 1 ? 0 : activeSlideIndex + 1
    });
  };

  /**
   * Returning all allSlides, which contains image
   */
  const renderSlides = () => {
    return activeSlides.map((slide, index) => {
      const { url } = slide;
      // eslint-disable-next-line react/no-array-index-key
      return <Slide key={url + index} content={url} />;
    });
  };

  return (
    <div className="carousel__container">
      <div className="carousel__slide_container">
        {allSlides.length > 1 && (
          <Arrow onClick={handlePrevSlideClick} testId="prevArrow" />
        )}
        <div id="slider" className="carousel__content" ref={carouselRef}>
          {showSlides && (
            <CaraouselContent
              translate={translate}
              transition={transition}
              width={carouselWidth * activeSlides.length}
            >
              {renderSlides()}
            </CaraouselContent>
          )}
        </div>
        {allSlides.length > 1 && (
          <Arrow
            direction={constants.ARROW_DIRECTION_RIGHT}
            onClick={handleNextSlideClick}
            testId="nextArrow"
            name={name}
          />
        )}
      </div>
      <Dots slides={allSlides} activeSlideIndex={activeSlideIndex} />
    </div>
  );
};

Carousel.propTypes = {
  slides: PropTypes.arrayOf({
    url: PropTypes.string.isRequired
  }),
  name: PropTypes.string.isRequired
};

export default Carousel;
