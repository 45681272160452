/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S.
 * and Foreign Patents, patent applications, and are protected by trade secret
 * or copyright law. Dissemination of this information, reproduction of this
 * material, and copying or distribution of this software is strictly forbidden
 * unless prior written permission is obtained from Pearson Education, Inc.
 */

import React, { useEffect, useState } from 'react';
import {
  Button,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  Switch,
  TextField
} from '@material-ui/core';
import { inject, observer, PropTypes } from 'mobx-react';
import { hot } from 'react-hot-loader/root';
import { isEqual } from 'lodash';
import constants from '../../constants';
import { updateLobMeta } from '../../services/lobMeta';

const LobMetaManager = props => {
  const {
    store: {
      LineOfBusiness: { lobList },
      LobMeta: { lobMetaList, updateSingleLobMeta },
      LobMetaMaster,
      LobMetaManager: {
        lob,
        meta,
        updateLob,
        updateMeta,
        resetMeta,
        getAllCheckedMetaId
      }
    },
    closeDialog,
    type
  } = props;

  const { SAVE, CANCEL, SELECT_LOB, LOB_META_TYPE_METADATA } = constants;

  const [isSaveEnabled, setIsSaveEnabled] = useState(false);
  const [defaultMeta, setDefaultMeta] = useState([]);

  // Initialize default list based on type(metadata/metalink)
  useEffect(() => {
    resetMeta();
    setIsSaveEnabled(false);
    if (LobMetaMaster[type]) {
      const defaultData = LobMetaMaster[type].map(data => ({
        id: data.id,
        label: data.label,
        value: type !== LOB_META_TYPE_METADATA
      }));
      setDefaultMeta(defaultData);
    }
    return () => {
      resetMeta();
    };
  }, [type, resetMeta, LobMetaMaster, LOB_META_TYPE_METADATA]);

  const handleToggle = (event, id) => {
    const metaToUpdate = meta.find(data => data.id === id);
    metaToUpdate.updateValue(event.target.checked);
    const matchedLobMeta = lobMetaList.find(metaItem => metaItem.lob === lob);
    if (matchedLobMeta) {
      setIsSaveEnabled(
        !isEqual([...matchedLobMeta[type]].sort(), getAllCheckedMetaId().sort())
      );
    } else {
      // no data is saved for selected lob in db
      // if toggle are not in default state enable save button
      setIsSaveEnabled(JSON.stringify(defaultMeta) !== JSON.stringify(meta));
    }
  };

  const handleLobChange = event => {
    const selectedLob = event.target.value;
    updateLob(selectedLob);
    const lobMeta = lobMetaList.find(metaItem => {
      return metaItem.lob === selectedLob;
    });
    if (lobMeta) {
      updateMeta(lobMeta[type], LobMetaMaster[type]);
    } else {
      // by default for metadata all value is false
      // for metalinks all value is true
      const data =
        type === LOB_META_TYPE_METADATA
          ? []
          : LobMetaMaster[type].map(item => item.id);
      updateMeta(data, LobMetaMaster[type]);
    }
    setIsSaveEnabled(false);
  };

  const handleSave = async () => {
    const data = getAllCheckedMetaId();
    const updatedMetaForSelectedLob = await updateLobMeta(type, lob, data);

    // updating data in LobMeta model
    updateSingleLobMeta(type, lob, updatedMetaForSelectedLob);
    setIsSaveEnabled(false);
  };

  const handleCancel = () => {
    closeDialog();
  };

  const metaToRender = lob ? meta : defaultMeta;

  return (
    <div className="project-meta-wrapper lob-modules-wrapper">
      <div className="project-meta-wrapper_lob-selection-wrapper">
        <TextField
          select
          inputProps={{
            'data-testid': 'lob-input',
            'aria-label': 'lob-input'
          }}
          label={SELECT_LOB}
          value={lob || ''}
          onChange={handleLobChange}
          className=""
          margin="dense"
          variant="filled"
          SelectProps={{
            SelectDisplayProps: {
              'data-testid': 'lob-dropdown'
            },
            MenuProps: {
              container: this,
              className: 'lob-dropdown-menu'
            }
          }}
        >
          {lobList &&
            lobList.map(opt => (
              <MenuItem key={opt.label} value={opt.aliasToLOB} name={opt.label}>
                {opt.label}
              </MenuItem>
            ))}
        </TextField>
      </div>
      <div className="project-meta-wrapper_meta-section">
        <List className="lob-module-list">
          {metaToRender.map(data => {
            return (
              <ListItem key={data.id}>
                <ListItemText
                  primary={data.label}
                  className={!lob ? 'meta-text' : ''}
                />
                <ListItemSecondaryAction>
                  <Switch
                    edge="end"
                    onChange={event => handleToggle(event, data.id)}
                    checked={data.value}
                    data-testid={data.label}
                    disabled={!lob}
                  />
                </ListItemSecondaryAction>
              </ListItem>
            );
          })}
        </List>
      </div>
      <div className="lob-module-action">
        <div>
          <Button
            className="btnCancel"
            data-testid="btn-cancel"
            onClick={handleCancel}
          >
            {CANCEL}
          </Button>

          <Button
            onClick={handleSave}
            className="btnSave"
            data-testid="btn-save"
            disabled={!isSaveEnabled}
          >
            {SAVE}
          </Button>
        </div>
      </div>
    </div>
  );
};

LobMetaManager.propTypes = {
  type: PropTypes.string.isRequired,
  closeDialog: PropTypes.func.isRequired,
  store: PropTypes.shape({
    LineOfBusiness: PropTypes.shape({
      lobList: PropTypes.arrayof(PropTypes.object)
    }),
    LobMeta: PropTypes.shape({
      lobMetaList: PropTypes.arrayOf({
        lob: PropTypes.string.isRequired,
        metadata: PropTypes.arrayOf(PropTypes.string),
        metalink: PropTypes.arrayOf(PropTypes.string)
      }),
      updateSingleLobMeta: PropTypes.func.isRequired
    }),
    LobMetaMaster: PropTypes.shape({
      metadata: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          label: PropTypes.string.isRequired
        })
      ),
      metalink: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          label: PropTypes.string.isRequired
        })
      )
    }),
    LobMetaManager: PropTypes.shape({
      lob: PropTypes.string.isRequired,
      meta: PropTypes.arrayOf({
        id: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        value: PropTypes.bool.isRequired
      }),
      updateLob: PropTypes.func.isRequired,
      updateMeta: PropTypes.func.isRequired,
      getAllCheckedMetaId: PropTypes.func.isRequired,
      resetMeta: PropTypes.func.isRequired
    })
  })
};

export default hot(inject('store')(observer(LobMetaManager)));
