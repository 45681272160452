/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S.
 * and Foreign Patents, patent applications, and are protected by trade secret
 * or copyright law. Dissemination of this information, reproduction of this
 * material, and copying or distribution of this software is strictly forbidden
 * unless prior written permission is obtained from Pearson Education, Inc.
 */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import {
  Button,
  TextField,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Switch
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { cloneDeep } from 'lodash';
import Constants from '../../../constants';
import { AdGroup } from '../../../constants/adGroups';
import { getAllUsersInfo } from '../../../services/getUserInfo';
import './SpecialPermissionUsers.style.scss';
import { updateSpecialPermissions } from '../../../services/updateSpecialPermissions';
import permissionConstants from '../../../constants/permissionConstants';

const SpecialPermissionUsers = props => {
  const {
    store: {
      SpecialPermissions: {
        specialPermissions: allSpecialPermissions,
        setPermission
      },
      UserInfo: { userId: loggedInUserId },
      MessageDialog: { showWarningMessageDialog }
    },
    closeDialog
  } = props;

  const {
    SPECIAL_PERMISSION_SEARCH_USER_LABEL,
    SPECIAL_PERMISSION_NO_USER_FOUND,
    SPECIAL_PERMISSION_EDIT_DISABLE_FOR_ADMIN,
    CONFIRM,
    CANCEL,
    NO_SPECIAL_PERMISSIONS
  } = Constants;

  const [allUsers, setAllUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [specialPermissionsOfUser, setSpecialPermissionsOfUser] = useState([]);
  const [specialPermissionsList, setSpecialPermissionsList] = useState([]);
  const [openSuggetion, setOpenSuggetion] = useState(false);
  const [isSaveEnabled, setIsSaveEnabled] = useState(false);
  // This is only sync changes of allSpecialPermissions with specialPermissionsList
  const [syncSpecialPermissions, setSyncSpecialPermissions] = useState(true);
  const [searchFieldValue, setSearchFieldValue] = useState('');

  // open the suggestion dropdown only when user will has typed atleast 2 letters
  const onChangeInputHandler = event => {
    const newInputValue = event.target.value;
    setSearchFieldValue(newInputValue);
    if (newInputValue.length > 1) {
      setOpenSuggetion(true);
    } else {
      setOpenSuggetion(false);
    }
  };

  const isAdminAndCreateNewProjectPermission = (user, permissionId) => {
    return (
      user &&
      user.adGroup === AdGroup.Admin &&
      permissionId === permissionConstants.CREATE_NEW_PROJECT
    );
  };

  /**
   * get permissions list in which for each permission value is true
   * if user has that permission
   * @param {*} user
   * @param {*} permissions
   * @returns permissions list
   */
  const getUserPermissions = (user, permissions) => {
    return permissions.map(permission => {
      let value = user ? permission.users.includes(user.userId) : false;
      const { permissionId } = permission;

      // if user is admin and permission is create new project
      // then mark is as true
      if (isAdminAndCreateNewProjectPermission(user, permissionId)) {
        value = true;
      }

      return {
        ...permission,
        value
      };
    });
  };

  // make call to fetch all users of UDB
  useEffect(() => {
    async function fetchUsers() {
      // fetching all users
      const allUsersInfo = await getAllUsersInfo(true);
      setAllUsers(allUsersInfo);
    }

    fetchUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // finding all assignable special permissions
    const permissions = allSpecialPermissions
      .filter(permission => permission.isAssignable)
      .map(permission => ({
        ...permission,
        value: false
      }));

    // when user is already selected in user search field,
    // update permissions with user permissions
    if (selectedUser) {
      const permissionsOfUser = getUserPermissions(selectedUser, permissions);
      setSpecialPermissionsOfUser(permissionsOfUser);
      setSpecialPermissionsList(permissionsOfUser);
    } else {
      setSpecialPermissionsList(permissions);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allSpecialPermissions, syncSpecialPermissions]);

  // hide suggetion dropdown
  const hideSuggestion = () => {
    setOpenSuggetion(false);
  };

  // onBlur of input box
  const onBlurHandler = () => {
    hideSuggestion();
  };

  const getOptionSelected = (option, value) => {
    return option.userId === value.userId || option.email === option.userId;
  };

  /**
   * On user change in user search field
   */
  const onUserChange = (_e, user) => {
    setSelectedUser(user);

    const updatedPermissions = getUserPermissions(user, specialPermissionsList);
    setSpecialPermissionsOfUser(updatedPermissions);
    setSpecialPermissionsList(cloneDeep(updatedPermissions));

    // hide suggestion box
    hideSuggestion();
    setIsSaveEnabled(false);
  };

  const resetStates = () => {
    setSelectedUser(null);
    setSpecialPermissionsOfUser([]);
    setIsSaveEnabled(false);
  };

  const handleToggle = (event, id) => {
    const updatedPermissions = specialPermissionsList.map(permission => {
      if (permission.permissionId === id) {
        return {
          ...permission,
          value: event.target.checked
        };
      }
      return permission;
    });

    setSpecialPermissionsList(updatedPermissions);

    setIsSaveEnabled(
      JSON.stringify(updatedPermissions) !==
        JSON.stringify(specialPermissionsOfUser)
    );
  };

  const getPermissionTooltip = permissionId => {
    let tooltip = '';
    if (isAdminAndCreateNewProjectPermission(selectedUser, permissionId)) {
      tooltip = SPECIAL_PERMISSION_EDIT_DISABLE_FOR_ADMIN;
    }
    return tooltip;
  };

  const isPermissionDisabled = permissionId => {
    return (
      !selectedUser ||
      isAdminAndCreateNewProjectPermission(selectedUser, permissionId)
    );
  };

  const renderSpecialPermissions = () => {
    return specialPermissionsList.map(item => {
      const { permissionId: id, label, value } = item;

      return (
        <ListItem key={`special-permission-${id}`}>
          <ListItemText primary={label} />
          <ListItemSecondaryAction title={getPermissionTooltip(id)}>
            <Switch
              edge="end"
              onChange={event => handleToggle(event, id)}
              checked={value}
              disabled={isPermissionDisabled(id)}
              inputProps={{
                'data-testid': `sp-permissions-${id}`
              }}
            />
          </ListItemSecondaryAction>
        </ListItem>
      );
    });
  };

  const handleCancel = () => {
    resetStates();
    closeDialog();
  };

  const handleSave = async () => {
    // finding all permissions whose value is updated
    const changedPermissions = [];
    specialPermissionsList.forEach(({ permissionId: id, value }) => {
      const userPermission = specialPermissionsOfUser.find(
        item => item.permissionId === id
      );
      if (userPermission && userPermission.value !== value) {
        changedPermissions.push({ id, value });
      }
    });

    // updating those permissions for user in DB
    const {
      success,
      updatedPermissions,
      message
    } = await updateSpecialPermissions(selectedUser.userId, changedPermissions);

    if (!success) {
      showWarningMessageDialog(message);
    }
    setPermission(updatedPermissions);
    setSyncSpecialPermissions(value => !value);

    setIsSaveEnabled(false);
  };

  return (
    <div className="special-permissions-modules-wrapper">
      <div className="search-user">
        <Autocomplete
          value={selectedUser}
          forcePopupIcon={false}
          open={openSuggetion}
          options={allUsers}
          getOptionLabel={option =>
            `${option.userId} | ${option.email.toLowerCase()}`
          }
          getOptionDisabled={option => option.userId === loggedInUserId}
          getOptionSelected={getOptionSelected}
          ListboxProps={{ style: { maxHeight: 200, overflow: 'auto' } }}
          renderOption={option => {
            const { email, userId } = option;
            let className = 'autocomplete-option';
            if (selectedUser && selectedUser.userId === userId) {
              className = 'autocomplete-selected-option';
            }
            return (
              <span
                className={className}
              >{`${userId} | ${email.toLowerCase()}`}</span>
            );
          }}
          onChange={onUserChange}
          noOptionsText={SPECIAL_PERMISSION_NO_USER_FOUND}
          renderInput={params => (
            <TextField
              {...params}
              value={searchFieldValue}
              label={SPECIAL_PERMISSION_SEARCH_USER_LABEL}
              onChange={onChangeInputHandler}
              onBlur={onBlurHandler}
            />
          )}
        />
      </div>

      <div>
        {specialPermissionsList.length === 0 && (
          <div className="no-permissions">{NO_SPECIAL_PERMISSIONS}</div>
        )}
        <List className="special-permissions-list">
          {renderSpecialPermissions()}
        </List>
      </div>

      <div className="special-permissions-action">
        <div>
          <Button
            className="btnCancel"
            data-testid="btn-cancel"
            onClick={handleCancel}
          >
            {CANCEL}
          </Button>

          <Button
            onClick={handleSave}
            className="btnSave"
            data-testid="btn-save"
            disabled={!isSaveEnabled}
          >
            {CONFIRM}
          </Button>
        </div>
      </div>
    </div>
  );
};

SpecialPermissionUsers.propTypes = {
  store: PropTypes.shape({
    SpecialPermissions: PropTypes.shape({
      specialPermissions: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          label: PropTypes.string
        })
      ).isRequired,
      setPermission: PropTypes.func.isRequired
    }),
    UserInfo: PropTypes.shape({
      userId: PropTypes.string.isRequired
    }),
    MessageDialog: PropTypes.shape({
      showWarningMessageDialog: PropTypes.func.isRequired
    })
  }),
  closeDialog: PropTypes.func.isRequired
};

export default inject('store')(observer(SpecialPermissionUsers));
