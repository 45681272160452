/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S.
 * and Foreign Patents, patent applications, and are protected by trade secret
 * or copyright law. Dissemination of this information, reproduction of this
 * material, and copying or distribution of this software is strictly forbidden
 * unless prior written permission is obtained from Pearson Education, Inc.
 */

import { axiosFetch } from './axiosFetch';
import apiConstants from '../constants/apiConstants';
import { urlModifier, postHeaders } from '../utils';

const logout = async () => {
  let response = false;
  const apiPath = urlModifier(apiConstants.LOGOUT_URL);
  const reqOptions = {
    ...postHeaders(),
    body: {}
  };

  response = await axiosFetch(reqOptions, apiPath).catch(error => error);
  if (response && response.status === 200) {
    response = true;
  }
  return response;
};

export default logout;
