/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S.
 * and Foreign Patents, patent applications, and are protected by trade secret
 * or copyright law. Dissemination of this information, reproduction of this
 * material, and copying or distribution of this software is strictly forbidden
 * unless prior written permission is obtained from Pearson Education, Inc.
 */

/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable react/jsx-boolean-value */
import React from 'react';
import PropTypes from 'prop-types';
import { Snackbar, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import MuiAlert from '@material-ui/lab/Alert';
import { observer, inject } from 'mobx-react';

/**
 * Alert component is used specificly to modify the look and feel of error message
 * @param props takes in the props required by Alert component, mainly choosing the message variant i.e. error, success, warning etc. supported
 */
export const Alert = props => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

/**
 * NOTE: Below component is not kept in seperate file as it is used with AlertMessage compnent.
 * AlertMessage component is used to display the snackbar popup messages
 * @param props getting the model related to message and extracting fuctions and variables to control the showing and info of the component
 */
const AlertMessage = props => {
  const {
    store: {
      AlertMessage: { alertMessages, removeAlertMessage }
    }
  } = props;

  if (alertMessages.length === 0) {
    return null;
  }
  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
      open={true}
      action={
        <IconButton size="small" color="inherit" aria-label="alert-close">
          <CloseIcon fontSize="small" />
        </IconButton>
      }
    >
      {/* controlling the look and feel of alert message with type property */}
      <Alert onClose={removeAlertMessage} severity={alertMessages[0].type}>
        {alertMessages[0].message}
      </Alert>
    </Snackbar>
  );
};

AlertMessage.propTypes = {
  store: PropTypes.shape({
    AlertMessage: PropTypes.shape({
      alertMessages: PropTypes.arrayof(PropTypes.string).isRequired,
      removeAlertMessage: PropTypes.func.isRequired
    })
  })
};

export default inject('store')(observer(AlertMessage));
