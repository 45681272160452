/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S.
 * and Foreign Patents, patent applications, and are protected by trade secret
 * or copyright law. Dissemination of this information, reproduction of this
 * material, and copying or distribution of this software is strictly forbidden
 * unless prior written permission is obtained from Pearson Education, Inc.
 */

import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export const UserManagerIcon = () => (
  <SvgIcon>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 8C14 10.21 12.21 12 10 12C7.79 12 6 10.21 6 8C6 5.79 7.79 4 10 4C12.21 4 14 5.79 14 8ZM12 8C12 6.9 11.1 6 10 6C8.9 6 8 6.9 8 8C8 9.1 8.9 10 10 10C11.1 10 12 9.1 12 8ZM4 18V17.35C4 17.01 4.16 16.69 4.41 16.54C6.1 15.53 8.03 15 10 15C10.03 15 10.05 15 10.08 15.01C10.18 14.31 10.38 13.64 10.67 13.03C10.45 13.01 10.23 13 10 13C7.58 13 5.32 13.67 3.39 14.82C2.51 15.34 2 16.32 2 17.35V20H11.26C10.84 19.4 10.51 18.72 10.29 18H4ZM20.6938 15.3963L20.6938 15.3962L20.69 15.37L21.83 14.36L20.83 12.63L19.38 13.12C19.06 12.85 18.7 12.64 18.3 12.49L18 11H16L15.7 12.49C15.3 12.64 14.94 12.85 14.62 13.12L13.17 12.63L12.17 14.36L13.31 15.37L13.3062 15.3962C13.2775 15.5967 13.25 15.7892 13.25 16C13.25 16.2108 13.2775 16.4033 13.3062 16.6038L13.31 16.63L12.17 17.64L13.17 19.37L14.62 18.88C14.94 19.15 15.3 19.36 15.7 19.51L16 21H18L18.3 19.51C18.7 19.36 19.06 19.15 19.38 18.88L20.83 19.37L21.83 17.64L20.69 16.63L20.6938 16.6038L20.6938 16.6037C20.7225 16.4033 20.75 16.2108 20.75 16C20.75 15.7892 20.7225 15.5967 20.6938 15.3963ZM17 18C15.9 18 15 17.1 15 16C15 14.9 15.9 14 17 14C18.1 14 19 14.9 19 16C19 17.1 18.1 18 17 18Z"
      fill="currentColor"
    />
    <mask
      id="mask0_0_1074UMicon"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="2"
      y="4"
      width="20"
      height="17"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 8C14 10.21 12.21 12 10 12C7.79 12 6 10.21 6 8C6 5.79 7.79 4 10 4C12.21 4 14 5.79 14 8ZM12 8C12 6.9 11.1 6 10 6C8.9 6 8 6.9 8 8C8 9.1 8.9 10 10 10C11.1 10 12 9.1 12 8ZM4 18V17.35C4 17.01 4.16 16.69 4.41 16.54C6.1 15.53 8.03 15 10 15C10.03 15 10.05 15 10.08 15.01C10.18 14.31 10.38 13.64 10.67 13.03C10.45 13.01 10.23 13 10 13C7.58 13 5.32 13.67 3.39 14.82C2.51 15.34 2 16.32 2 17.35V20H11.26C10.84 19.4 10.51 18.72 10.29 18H4ZM20.6938 15.3963L20.6938 15.3962L20.69 15.37L21.83 14.36L20.83 12.63L19.38 13.12C19.06 12.85 18.7 12.64 18.3 12.49L18 11H16L15.7 12.49C15.3 12.64 14.94 12.85 14.62 13.12L13.17 12.63L12.17 14.36L13.31 15.37L13.3062 15.3962C13.2775 15.5967 13.25 15.7892 13.25 16C13.25 16.2108 13.2775 16.4033 13.3062 16.6038L13.31 16.63L12.17 17.64L13.17 19.37L14.62 18.88C14.94 19.15 15.3 19.36 15.7 19.51L16 21H18L18.3 19.51C18.7 19.36 19.06 19.15 19.38 18.88L20.83 19.37L21.83 17.64L20.69 16.63L20.6938 16.6038L20.6938 16.6037C20.7225 16.4033 20.75 16.2108 20.75 16C20.75 15.7892 20.7225 15.5967 20.6938 15.3963ZM17 18C15.9 18 15 17.1 15 16C15 14.9 15.9 14 17 14C18.1 14 19 14.9 19 16C19 17.1 18.1 18 17 18Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_0_1074UMicon)">
      <rect width="24" height="24" fill="currentColor" fillOpacity="0.87" />
    </g>
  </SvgIcon>
);
