/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S.
 * and Foreign Patents, patent applications, and are protected by trade secret
 * or copyright law. Dissemination of this information, reproduction of this
 * material, and copying or distribution of this software is strictly forbidden
 * unless prior written permission is obtained from Pearson Education, Inc.
 */

import React, { useState } from 'react';
import { Tabs, Tab, IconButton, Modal } from '@material-ui/core';
import AnnouncementIcon from '@material-ui/icons/Announcement';
import CloseIcon from '@material-ui/icons/Close';
import ReleaseManagement from '../release-management/ReleaseManagement';
import FeatureManagement from '../release-management/FeatureManagement';
import NotificationAndTutorialMgmt from '../release-management/NotificationAndTutorialMgmt';
import { PageTourSlideMgmt } from '../release-management/PageTourSlideMgmt';
import ProjectActivity from '../release-management/ProjectActivity';
import FileUrlsMgmt from '../release-management/FileUrlsMgmt';
import Constants from '../../constants';
import './ReleaseAndNotificationTabs.style.scss';

const ReleaseAndNotificationTabs = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const openReleaseDialog = () => {
    setOpenDialog(true);
  };

  const closeReleaseDialog = () => {
    setOpenDialog(false);
  };

  const body = (
    <div className="tabdesign">
      <div className="header">
        <Tabs value={selectedTab} onChange={handleChange}>
          <Tab
            data-testid="releaseManagement"
            label={Constants.NEW_RELEASE_MENU_TITLE}
            aria-label="release-management-tab"
          />
          <Tab
            data-testid="notificationTab"
            label={Constants.NOTIFICATIONS_AND_TUTORIALS}
            aria-label="notifications-and-tutorials-tab"
          />
          <Tab
            data-testid="tourSlidesTab"
            label={Constants.TOUR_SLIDES}
            aria-label="page-tour-slides-tab"
          />
          <Tab
            data-testid="projectActivityTab"
            label={Constants.PROJECT_ACTIVITY}
            aria-label="project-activity-tab"
          />
          <Tab
            data-testid="fileUrlsTab"
            label={Constants.FILE_URLS_MGMT}
            aria-label="file-urls-tab"
          />
          <Tab
            data-testid="featureManagementTab"
            label={Constants.FEATURE_MANAGER}
            aria-label="feature-Management-tab"
          />
        </Tabs>
        <IconButton
          aria-label="release-management-NT-tool-dialog-close"
          className="close-button"
          onClick={closeReleaseDialog}
          data-testid="closeIcon"
        >
          <CloseIcon />
        </IconButton>
      </div>
      {selectedTab === 0 && (
        <ReleaseManagement handleClose={closeReleaseDialog} />
      )}
      {selectedTab === 1 && <NotificationAndTutorialMgmt />}
      {selectedTab === 2 && <PageTourSlideMgmt />}
      {selectedTab === 3 && <ProjectActivity />}
      {selectedTab === 4 && <FileUrlsMgmt />}
      {selectedTab === 5 && (
        <FeatureManagement handleClose={closeReleaseDialog} />
      )}
    </div>
  );
  return (
    <>
      <button
        className="user-detail__management"
        type="button"
        onClick={openReleaseDialog}
        aria-label="release-NT-message-console-icon"
      >
        <AnnouncementIcon />
        {Constants.NEW_APP_MANAGEMENT}
      </button>
      <Modal
        open={openDialog}
        onClose={closeReleaseDialog}
        className="dialogDesign"
      >
        {body}
      </Modal>
    </>
  );
};

ReleaseAndNotificationTabs.propTypes = {};

export default ReleaseAndNotificationTabs;
