/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S.
 * and Foreign Patents, patent applications, and are protected by trade secret
 * or copyright law. Dissemination of this information, reproduction of this
 * material, and copying or distribution of this software is strictly forbidden
 * unless prior written permission is obtained from Pearson Education, Inc.
 */

import React, { useState, useEffect } from 'react';
import {
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  Tooltip
} from '@material-ui/core';
import { getProjectActivity } from '../../services/getProjectActivity';
import { getFormattedDate } from '../../utils';
import CustomInifiniteScroll from '../custom-infinite-scroll/CustomInfiniteScroll';
import './ProjectActivity.style.scss';

let lastPageIndex = 0;

const ProjectActivity = () => {
  const [projectActivityRow, setProjActivities] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [pageIndex, setPageIndex] = useState(0);

  useEffect(() => {
    const fetchProjectActivity = async () => {
      const data = await getProjectActivity();

      if (data && data.transactions.length > 0) {
        setProjActivities(data.transactions);
      }
      if (data.hasMore) {
        setPageIndex(pageIndex + 1);
      }
      setHasMore(data.hasMore);
    };

    // Fetch the project activity only wen data is not already retrieved
    if (projectActivityRow.length === 0) {
      fetchProjectActivity();
    }

    // when dom unmounted cleanup the value of lastPageIndex
    return function cleanup() {
      lastPageIndex = 0;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadMoreProjects = async () => {
    if (lastPageIndex === pageIndex - 1) {
      const data = await getProjectActivity(pageIndex + 1);
      setHasMore(data.hasMore);
      setPageIndex(pageIndex + 1);
      setProjActivities(prev => [...prev, ...data.transactions]);
      lastPageIndex += 1;
    }
  };

  const projectActivityColumns = [
    {
      id: 'projectVisibility',
      label: 'Status',
      classes: 'project-activity__table-head--table-heading-status',
      'data-testid': 'statusColumn'
    },
    {
      id: 'title',
      label: 'Title',
      classes: 'project-activity__table-head--table-heading-title',
      'data-testid': 'titleColumn'
    },
    {
      id: 'userName',
      label: 'User Name',
      classes: 'project-activity__table-head--table-heading-userName',
      'data-testid': 'userNameColumn'
    },
    {
      id: 'id',
      label: 'Distributable URN',
      classes: 'project-activity__table-head--table-heading-durn',
      'data-testid': 'durnColumn'
    },
    {
      id: 'entityUrn',
      label: 'Entity URN',
      classes: 'project-activity__table-head--table-heading-entityurn',
      'data-testid': 'entityurnColumn'
    },
    {
      id: 'timeUpdated',
      label: 'Update Time',
      classes: 'project-activity__table-head--table-heading-time',
      'data-testid': 'timeColumn'
    }
  ];

  /**
   * Returning table header to render in tableHead
   */
  const renderTableHead = () => {
    return projectActivityColumns.map(column => {
      const { id, label, classes, ...otherProps } = column;

      return (
        <TableCell key={id} className={`${classes}`} {...otherProps}>
          {label && (
            <Tooltip title={label}>
              <span>{label}</span>
            </Tooltip>
          )}
        </TableCell>
      );
    });
  };

  /**
   * Returning table cells to render in table body
   */
  const renderTableRows = () => {
    return projectActivityRow.map((row, index) => {
      const {
        id,
        entityUrn,
        userName,
        title,
        timeUpdated,
        projectVisibility
      } = row;
      return (
        <TableRow hover key={timeUpdated} data-testid={`row-${index + 1}`}>
          <TableCell className="project-activity__table-body--projectVisibility">
            <div title={projectVisibility}>{projectVisibility}</div>
          </TableCell>
          <TableCell className="project-activity__table-body--title">
            <div title={title}>{title}</div>
          </TableCell>
          <TableCell className="project-activity__table-body--userName">
            <div title={userName}>{userName}</div>
          </TableCell>
          <TableCell className="project-activity__table-body--id">
            <div title={id}>{id}</div>
          </TableCell>
          <TableCell className="project-activity__table-body--entityUrn">
            <div title={entityUrn}>{entityUrn}</div>
          </TableCell>
          <TableCell className="project-activity__table-body--timeUpdated">
            <div title={timeUpdated}>
              {getFormattedDate(timeUpdated, 'MM/DD/YY HH:mm:ss')}
            </div>
          </TableCell>
        </TableRow>
      );
    });
  };

  return (
    <div className="project-activity customized-table-styles">
      <TableContainer
        className="project-activity__table-container"
        id="projectActivityTableContainer"
      >
        <Table stickyHeader className="project-activity__table">
          <TableHead
            className="project-activity__table-head"
            data-testid="projectActivityHead"
          >
            <TableRow>{renderTableHead()}</TableRow>
          </TableHead>
          <CustomInifiniteScroll
            loadMore={loadMoreProjects}
            hasMore={hasMore}
            useWindow={false}
            element="tbody"
            getScrollParent={() =>
              document.getElementById('projectActivityTableContainer')
            }
            threshold={10}
            pageIndex={pageIndex}
          >
            {renderTableRows()}
          </CustomInifiniteScroll>
        </Table>
      </TableContainer>
    </div>
  );
};

export default ProjectActivity;
