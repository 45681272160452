/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S.
 * and Foreign Patents, patent applications, and are protected by trade secret
 * or copyright law. Dissemination of this information, reproduction of this
 * material, and copying or distribution of this software is strictly forbidden
 * unless prior written permission is obtained from Pearson Education, Inc.
 */

/* eslint-disable jsx-a11y/media-has-caption */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, ButtonBase } from '@material-ui/core';
import './ImageDialog.style.scss';

const ImageDialog = props => {
  const { NoImageFound, thumbnailUrl } = props;

  const [openImageDialog, setOpenImageDialog] = useState(false);

  const handleImageDialogClose = () => {
    setOpenImageDialog(false);
  };

  const handleImageClick = () => {
    if (thumbnailUrl !== null) {
      setOpenImageDialog(true);
    }
  };

  const body = (
    <div className="imageContent" data-testid="enlarge-image">
      <div className="image-content">
        <img alt="" src={thumbnailUrl} />
      </div>
    </div>
  );

  return (
    <>
      <ButtonBase
        className={thumbnailUrl ? 'image' : 'no-image'}
        onClick={() => handleImageClick()}
        data-testid="card-container-button-base"
        aria-label="card-container-button-base"
      >
        {thumbnailUrl && <img alt="" src={thumbnailUrl} />}
        {!thumbnailUrl && (
          <img alt="" src={NoImageFound} className="no-image-found" />
        )}
      </ButtonBase>
      <Modal open={openImageDialog} onClose={handleImageDialogClose}>
        {body}
      </Modal>
    </>
  );
};

ImageDialog.propTypes = {
  NoImageFound: PropTypes.string.isRequired,
  thumbnailUrl: PropTypes.string.isRequired
};

export default ImageDialog;
