/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S.
 * and Foreign Patents, patent applications, and are protected by trade secret
 * or copyright law. Dissemination of this information, reproduction of this
 * material, and copying or distribution of this software is strictly forbidden
 * unless prior written permission is obtained from Pearson Education, Inc.
 */

import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export const TableViewIcon = () => (
  <SvgIcon width="24px" height="24px" viewBox="0 0 24 24">
    <path
      d="M19.5,3 L4.5,3 C3.4,3 2.5,3.9 2.5,5 L2.5,19 C2.5,20.1 3.4,21 4.5,21 L19.5,21 C20.6,21 21.5,20.1 21.5,19 L21.5,5 C21.5,3.9 20.6,3 19.5,3 Z M19.5,5 L19.5,8 L4.5,8 L4.5,5 L19.5,5 Z M14.5,19 L9.5,19 L9.5,10 L14.5,10 L14.5,19 Z M4.5,10 L7.5,10 L7.5,19 L4.5,19 L4.5,10 Z M16.5,19 L16.5,10 L19.5,10 L19.5,19 L16.5,19 Z"
      id="path-1"
    ></path>
  </SvgIcon>
);
