/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S.
 * and Foreign Patents, patent applications, and are protected by trade secret
 * or copyright law. Dissemination of this information, reproduction of this
 * material, and copying or distribution of this software is strictly forbidden
 * unless prior written permission is obtained from Pearson Education, Inc.
 */

import React from 'react';
import SvgIcon from '@material-ui/core/SvgIcon';

export const GridViewIcon = () => (
  <SvgIcon width="24px" height="24px" viewBox="0 0 24 24">
    <path
      d="M11,3 L11,11 L3,11 L3,3 L11,3 Z M9,5 L5,5 L5,9 L9,9 L9,5 Z M21,3 L21,11 L13,11 L13,3 L21,3 Z M19,5 L15,5 L15,9 L19,9 L19,5 Z M21,13 L21,21 L13,21 L13,13 L21,13 Z M19,15 L15,15 L15,19 L19,19 L19,15 Z M11,13 L11,21 L3,21 L3,13 L11,13 Z M9,15 L5,15 L5,19 L9,19 L9,15 Z"
      id="path-1"
    ></path>
  </SvgIcon>
);
