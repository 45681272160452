/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S.
 * and Foreign Patents, patent applications, and are protected by trade secret
 * or copyright law. Dissemination of this information, reproduction of this
 * material, and copying or distribution of this software is strictly forbidden
 * unless prior written permission is obtained from Pearson Education, Inc.
 */

/* eslint-disable no-param-reassign */
import { types } from 'mobx-state-tree';
import PublishInfo from './PublishInfo';
import Alfresco from './Alfresco';

export default types.model('ProjectVersionData', {
  id: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  origin: types.maybeNull(types.string),
  status: types.frozen(),
  versionNo: types.maybeNull(types.number),
  dateCreated: types.maybeNull(types.string),
  userApprover: types.maybeNull(types.string),
  userApproverFirstName: types.maybeNull(types.string),
  userApproverLastName: types.maybeNull(types.string),
  publishInfo: types.maybeNull(PublishInfo),
  alfresco: types.maybeNull(Alfresco),
  authorName: types.maybeNull(types.string),
  quadFolder: types.maybeNull(types.string)
});
