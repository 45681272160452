/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S.
 * and Foreign Patents, patent applications, and are protected by trade secret
 * or copyright law. Dissemination of this information, reproduction of this
 * material, and copying or distribution of this software is strictly forbidden
 * unless prior written permission is obtained from Pearson Education, Inc.
 */

import React, { useState, useEffect, Fragment, useRef } from 'react';
import { observer, inject } from 'mobx-react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import CircularProgress from '@material-ui/core/CircularProgress';

import NotificationCard from '../../components/notifications/NotificationsCard';
import VideoDialog from '../video-tutorial/VideoTutorial';
import Constants from '../../constants';

import './NotificationsWrapper.style.scss';

const NOTIFICATION = 'N';
const TUTORIAL = 'T';

const NotificationsWrapper = props => {
  const {
    store: {
      NotificationDrawer: {
        isLoaded,
        notifications,
        tutorials,
        fetchResources,
        hasNotificationAlert,
        removeNotificationAlert,
        closeNotificationDrawer
      },
      PageTour: { startTour }
    }
  } = props;
  const cardsWrapper = useRef(null);

  const [notificationType, setNotificationType] = useState(NOTIFICATION);
  const [playVideo, setPlayVideo] = useState(null);

  const showNotifications = () => setNotificationType(NOTIFICATION);
  const showTutorials = () => setNotificationType(TUTORIAL);

  const closeVideoLink = () => setPlayVideo(null);
  const playVideoLink = videoLink => setPlayVideo(videoLink);

  // Remove notification alert badge
  useEffect(() => {
    if (hasNotificationAlert) {
      removeNotificationAlert();
    }
  }, [removeNotificationAlert, hasNotificationAlert]);

  // load Notifications
  useEffect(() => {
    async function getNotificationsData() {
      await fetchResources();
    }
    if (!isLoaded || hasNotificationAlert) {
      getNotificationsData();
    }
  }, [fetchResources, isLoaded, hasNotificationAlert]);

  // Set scrollbar position on change of notification Type
  useEffect(() => {
    cardsWrapper.current.scrollTop = 0;
  }, [notificationType]);

  const showLoader = !isLoaded || hasNotificationAlert;

  return (
    <Fragment>
      <div className="notifications__buttons">
        <ButtonGroup color="primary" aria-label="outlined primary button group">
          <Button
            onClick={showNotifications}
            className={notificationType === NOTIFICATION ? 'active' : ''}
            aria-label="notification-drawer-notifications"
          >
            {Constants.NOTIFICATIONS}
          </Button>
          <Button
            onClick={showTutorials}
            className={notificationType === TUTORIAL ? 'active' : ''}
            aria-label="notification-drawer-tutorials"
          >
            {Constants.TUTORIALS}
          </Button>
        </ButtonGroup>
      </div>
      <div className="notifications__cards" ref={cardsWrapper}>
        {showLoader && (
          <div className="spinner-wrapper">
            <CircularProgress className="spinner" size={50} disableShrink />
          </div>
        )}
        <div className={notificationType === TUTORIAL ? 'hide-section' : ''}>
          {notifications.map(notification => {
            return (
              <NotificationCard
                key={notification.resourceId}
                playVideoLink={playVideoLink}
                closeNotificationDrawer={closeNotificationDrawer}
                startTour={startTour}
                {...notification}
              />
            );
          })}
        </div>
        <div
          className={notificationType === NOTIFICATION ? 'hide-section' : ''}
        >
          {tutorials.map(notification => {
            return (
              <NotificationCard
                key={notification.resourceId}
                playVideoLink={playVideoLink}
                closeNotificationDrawer={closeNotificationDrawer}
                startTour={startTour}
                {...notification}
              />
            );
          })}
        </div>
      </div>
      {playVideo && (
        <div className="notifications__video">
          <VideoDialog videoLink={playVideo} handleClose={closeVideoLink} />
        </div>
      )}
    </Fragment>
  );
};

NotificationsWrapper.propTypes = {
  store: PropTypes.shape({
    NotificationDrawer: PropTypes.shape({
      isLoaded: PropTypes.bool.isRequired,
      notifications: PropTypes.arrayof(PropTypes.String).isRequired,
      tutorials: PropTypes.arrayof(PropTypes.String).isRequired,
      fetchResources: PropTypes.func.isRequired,
      hasNotificationAlert: PropTypes.func.isRequired,
      removeNotificationAlert: PropTypes.func.isRequired,
      closeNotificationDrawer: PropTypes.func.isRequired
    }),
    UserInfo: PropTypes.shape({
      removeNotificationAlert: PropTypes.func.isRequired
    }),
    PageTour: PropTypes.shape({
      startTour: PropTypes.func.isRequired
    })
  })
};

export default inject('store')(observer(NotificationsWrapper));
