/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S.
 * and Foreign Patents, patent applications, and are protected by trade secret
 * or copyright law. Dissemination of this information, reproduction of this
 * material, and copying or distribution of this software is strictly forbidden
 * unless prior written permission is obtained from Pearson Education, Inc.
 */

import React from 'react';
import PropTypes from 'prop-types';
import MuiDialogActions from '@material-ui/core/DialogActions';
import './Dialog.style.scss';

/**
 * Render Dialog content.
 */
const DialogActions = props => {
  const { children, className } = props;
  const cssClass = className || 'dialog-footer';
  return <MuiDialogActions className={cssClass}>{children}</MuiDialogActions>;
};

DialogActions.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string
};

export default DialogActions;
