/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S.
 * and Foreign Patents, patent applications, and are protected by trade secret
 * or copyright law. Dissemination of this information, reproduction of this
 * material, and copying or distribution of this software is strictly forbidden
 * unless prior written permission is obtained from Pearson Education, Inc.
 */

/* eslint-disable no-param-reassign */
import { types } from 'mobx-state-tree';
import {
  getAssetDownloadConfig,
  getConfig as getConfigService
} from '../services/getConfig';

const AlfrescoDownloadConfig = types
  .model('ALFRRESCO_DOWNLOAD_CONFIG', {
    CHUNK_SIZE: 7000000,
    TOTAL_RETRIES: 3,
    DELAY_BETWEEN_RETRY: 2000,
    API_CALLS_CONCURRANCY: 3,
    CHUNK_SIZES: types.optional(types.array(types.number), [1000000, 200000])
  })
  .actions(self => ({
    setAlfrescoConfig(config) {
      const configKeys = Object.keys(config);
      configKeys.forEach(key => {
        self[key] = config[key];
      });
    },
    async fetchAlfrescoConfig() {
      const config = await getAssetDownloadConfig();
      self.setAlfrescoConfig(config);
    }
  }));

export default types
  .model('Config', {
    ELM_URL: types.maybeNull(types.string),
    CYPRESS_URL: types.maybeNull(types.string),
    GPS_DISTRIBUTE_URL: types.maybeNull(types.string),
    WILLOW_URL: types.maybeNull(types.string),
    VERSION_PREVIEW_BASE_URL: types.maybeNull(types.string),
    ALFRESCO_CITE_PATTERNS_URL: types.maybeNull(types.string),
    ALFRESCO_SITE_URL: types.maybeNull(types.string),
    PREFLIGHT_REFRESH_DURATION: types.maybeNull(types.string),
    GOOGLE_ANALYTICS_TRACKING_ID: types.maybeNull(types.string),
    BUILD_NUMBER: types.maybeNull(types.string),
    GIT_TAG: types.maybeNull(types.string),
    GIT_COMMIT: types.maybeNull(types.string),
    GIT_BRANCH: types.maybeNull(types.string),
    AUTHOR_NAME: types.maybeNull(types.string),
    CTOOL_HOST: types.maybeNull(types.string),
    RELEASE_NUMBER: types.maybeNull(types.string),
    GLOSSARY_OPTIONS: types.maybeNull(types.string),
    ALFRESCO_DOWNLOAD_CONFIG: types.optional(AlfrescoDownloadConfig, {})
  })
  .actions(self => ({
    /**
     * @function - Fetching config data using getConfig service (from /config api)
     */
    async getConfig() {
      const config = await getConfigService();
      self.setConfigData(config);
    },

    /**
     * @function - Saving all the config data in self object
     * @param {config}
     */
    setConfigData(config) {
      const configKeys = Object.keys(config);
      configKeys.forEach(key => {
        self[key] = config[key];
      });
    }
  }));
