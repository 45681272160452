/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S.
 * and Foreign Patents, patent applications, and are protected by trade secret
 * or copyright law. Dissemination of this information, reproduction of this
 * material, and copying or distribution of this software is strictly forbidden
 * unless prior written permission is obtained from Pearson Education, Inc.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';
import { hot } from 'react-hot-loader/root';
import { SaveAlt } from '@material-ui/icons';
import Preferences from '../../components/preferences/Preferences';
import ReleaseAndNotificationTabs from '../../components/release-and-notification-tabs/ReleaseAndNotificationTabs';
import UserManager from '../../components/user-manager/UserManager';
import LobManagerTabs from '../lob-manager/LobManagerTabs';
import logoutApi from '../../services/logout';
import { logout } from '../../utils';
import Constants from '../../constants';
import permissionConstants from '../../constants/permissionConstants';
import featureConstants from '../../constants/featureConstants';
import UserAccounts from '../user-accounts/UserAccounts';

const UserMenu = props => {
  const {
    store: {
      UserInfo: { userId },
      SpecialPermissions: { isPermissionExist },
      FeatureManager: { featureList }
    }
  } = props;

  const handleLogout = async () => {
    // calling api to delete the pearsonsession token
    await logoutApi();

    // deleting the cookie and reloading page
    logout(userId);
  };

  const isSpecialPermissionExist = isPermissionExist(
    permissionConstants.WHATS_NEW_CONTENT
  );

  const isPermissionExistForLobManager = isPermissionExist(
    permissionConstants.LOB_MANAGER
  );

  const isSpecialPermissionExistForUserAccountsBU = isPermissionExist(
    permissionConstants.USER_ACCOUNTS_BU
  );

  const isPermissionExistForUser =
    isSpecialPermissionExist && isSpecialPermissionExist.users.includes(userId);

  const isPermissionForLobManagerExitForUser =
    isPermissionExistForLobManager &&
    isPermissionExistForLobManager.users.includes(userId);

  const isPermissionForUserAccountsBUExistForUser =
    isSpecialPermissionExistForUserAccountsBU &&
    isSpecialPermissionExistForUserAccountsBU.users.includes(userId) &&
    featureList.find(
      item =>
        item.featureId === featureConstants.USER_ACCOUNTS && item.isEnabled
    );

  const isPreferencesEnabledForUser = featureList.find(
    item => item.featureId === featureConstants.PREFERENCES && item.isEnabled
  );
  return (
    <>
      {isPreferencesEnabledForUser && <Preferences />}
      {isPermissionForUserAccountsBUExistForUser && <UserAccounts />}
      {isPermissionExistForUser && <ReleaseAndNotificationTabs />}
      {isPermissionForLobManagerExitForUser && <LobManagerTabs />}
      {isPermissionExistForUser && <UserManager />}
      <button
        className="user-detail__logout"
        type="button"
        onClick={handleLogout}
        aria-label="user-details-menu-logout"
      >
        <SaveAlt /> {Constants.LOGOUT_TEXT}
      </button>
    </>
  );
};

UserMenu.propTypes = {
  store: PropTypes.shape({
    UserInfo: PropTypes.shape({
      firstName: PropTypes.string.isRequired,
      email: PropTypes.string.isRequired,
      isAuthorized: PropTypes.bool.isRequired,
      userId: PropTypes.string.isRequired
    }),
    SpecialPermissions: PropTypes.shape({
      isPermissionExist: PropTypes.func.isRequired
    }),
    FeatureManager: PropTypes.shape({
      featureList: PropTypes.arrayOf(PropTypes.object)
    })
  })
};

export default hot(inject('store')(observer(UserMenu)));
