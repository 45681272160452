/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S.
 * and Foreign Patents, patent applications, and are protected by trade secret
 * or copyright law. Dissemination of this information, reproduction of this
 * material, and copying or distribution of this software is strictly forbidden
 * unless prior written permission is obtained from Pearson Education, Inc.
 */

import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { LockIcon } from '../../custom/icons/LockIcon';
import Header from '../Header/Header';
import Constants from '../../constants';
import './ForbiddenPage.style.scss';

const ForbiddenPage = props => {
  const { userName, REQUEST_AD_GROUP_PDF } = props;
  const {
    FORBIDDEN_MESSAGE1,
    FORBIDDEN_MESSAGE2,
    AD_GROUP_EDITOR,
    FORBIDDEN_MESSAGE3,
    FORBIDDEN_MESSAGE4,
    FORBIDDEN_MESSAGE5,
    BROWSER_TITLE,
    CLICK_HERE
  } = Constants;
  return (
    <Fragment>
      <Helmet>
        <title>{BROWSER_TITLE}</title>
      </Helmet>
      <Header isUnAuthorized showReleaseVersion />
      {userName && (
        <div className="forbidden-page-wrapper">
          <div>
            <p className="welcome-text"> {`Welcome, ${userName}`}</p>
            <p>{FORBIDDEN_MESSAGE1}</p>
            <p>{FORBIDDEN_MESSAGE2}</p>
            <div className="ad-groups-text">
              <div>{AD_GROUP_EDITOR}</div>
            </div>
            <p>{FORBIDDEN_MESSAGE3}</p>
            <p>
              <a href={REQUEST_AD_GROUP_PDF} target="_blank">
                {CLICK_HERE}
              </a>
              &nbsp; {FORBIDDEN_MESSAGE4}
              UDB
            </p>
            <p>
              <a
                href="https://pearson.service-now.com/support/logticket.do"
                target="_blank"
              >
                {CLICK_HERE}
              </a>
              &nbsp; {FORBIDDEN_MESSAGE5}
            </p>
          </div>
          <div className="forbidden-lock-image">
            <LockIcon fontSize="large" />
          </div>
        </div>
      )}
    </Fragment>
  );
};

ForbiddenPage.propTypes = {
  userName: PropTypes.string.isRequired,
  REQUEST_AD_GROUP_PDF: PropTypes.string
};

export default ForbiddenPage;
