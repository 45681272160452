/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S.
 * and Foreign Patents, patent applications, and are protected by trade secret
 * or copyright law. Dissemination of this information, reproduction of this
 * material, and copying or distribution of this software is strictly forbidden
 * unless prior written permission is obtained from Pearson Education, Inc.
 */

import React, { useState, useEffect } from 'react';
import { observer, inject } from 'mobx-react';
import { hot } from 'react-hot-loader/root';
import { cloneDeep } from 'lodash';
import PropTypes from 'prop-types';
import { PeopleAlt } from '@material-ui/icons';
import LockIcon from '@material-ui/icons/Lock';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import { Button, IconButton } from '@material-ui/core';
import {
  CustomDialog,
  DialogHeader,
  DialogContent
} from '../../components/common/Dialog';
import {
  getUserBUAffiliation,
  saveBUAffiliations
} from '../../services/getUserBUAffiliation';
import { getAllUsersInfo } from '../../services/getUserInfo';
import Constants from '../../constants';
import { BUAffiliationOrder } from '../../constants/projectStatusAndPlatformConstants';
import './UserAccounts.style.scss';

const UserAccounts = props => {
  const {
    store: {
      AllUsersInfo: { allUsersInfo, setAllUsersInfo },
      BusinessUnits: { allBusinessUnits }
    }
  } = props;

  const [openDialog, setOpenDialog] = useState(false);
  const [selectedUser, setSelectedUser] = useState('');
  const [userAffiliations, setUserAffiliations] = useState([]);
  const [affiliationsBeforeUpdate, setAffiliationsBeforeUserUpdate] = useState(
    []
  );
  const [affiliationsOrder, setAffiliationsOrder] = useState([]);
  const [isSaveEnabled, setIsSaveEnabled] = useState(false);
  const { SAVE, CANCEL, BUSINESS_UNIT_AFFILIATIONS, USERS } = Constants;

  useEffect(() => {
    const allAffiliations = cloneDeep(allBusinessUnits);
    // Order Affiliations list
    allAffiliations.sort(
      (a, b) =>
        BUAffiliationOrder.indexOf(a.id) - BUAffiliationOrder.indexOf(b.id)
    );

    setAffiliationsOrder(allAffiliations);
    // At first when user not selected locking all affiliations and disabling lock button
    const updatedAffiliations = allAffiliations.map(item => ({
      ...item,
      locked: true
    }));

    setUserAffiliations(updatedAffiliations);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allBusinessUnits]);

  useEffect(() => {
    async function fetchData() {
      const usersInfo = await getAllUsersInfo();

      setAllUsersInfo(usersInfo);
    }
    if (openDialog && allUsersInfo.length === 0) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openDialog]);

  useEffect(() => {
    async function fetchUserBUAffiliation() {
      const UserBusinessUnitAffiliations = await getUserBUAffiliation(
        selectedUser
      );

      // As default making all affiliations to be locked
      let businessUnitDataCopy = cloneDeep(affiliationsOrder);

      businessUnitDataCopy = businessUnitDataCopy.map(item => ({
        ...item,
        locked: false
      }));

      const lockedArray = [];
      if (UserBusinessUnitAffiliations.length > 0) {
        UserBusinessUnitAffiliations.forEach(item => {
          if (item.locked) {
            lockedArray.push(item.id);
          }
        });

        if (lockedArray.length > 0) {
          lockedArray.forEach(id => {
            const index = businessUnitDataCopy.findIndex(
              bUData => bUData.id === id
            );
            businessUnitDataCopy[index] = {
              ...businessUnitDataCopy[index],
              locked: true
            };
          });
        }
      }
      setUserAffiliations(businessUnitDataCopy);
      setAffiliationsBeforeUserUpdate(businessUnitDataCopy);
    }
    if (selectedUser) {
      fetchUserBUAffiliation();
      setIsSaveEnabled(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUser]);

  const openUserAccountsDialog = () => {
    setOpenDialog(true);
  };

  const closeUserAccountDialog = () => {
    setOpenDialog(false);
    setSelectedUser('');
  };

  const handlelockUnlockBtnClick = id => {
    const businessUnitDataCopy = cloneDeep(userAffiliations);
    const index = businessUnitDataCopy.findIndex(t => t.id === id);
    businessUnitDataCopy[index] = {
      ...businessUnitDataCopy[index],
      locked: !businessUnitDataCopy[index].locked
    };
    setUserAffiliations(businessUnitDataCopy);
    const businessUnitDataAfterUpdate = businessUnitDataCopy;

    // comparing affiliationsBeforeUpdate and businessUnitDataAfterUpdate to enable Save button
    setIsSaveEnabled(
      JSON.stringify(affiliationsBeforeUpdate) !==
        JSON.stringify(businessUnitDataAfterUpdate)
    );
  };

  const handleSaveUserAccount = async () => {
    const businessUnit = [];
    userAffiliations.forEach(item => {
      if (item.locked) {
        businessUnit.push(item.id);
      }
    });
    const payload = [
      {
        userId: selectedUser,
        businessUnit
      }
    ];
    const res = await saveBUAffiliations(payload);
    if (res) {
      setIsSaveEnabled(false);
    }
  };

  const renderBusinessAffiliations = () => {
    return (
      userAffiliations &&
      userAffiliations.map(item => {
        const { id, label, locked } = item;
        return (
          <div key={id} className="user-accounts-dialog__affiliations">
            <>
              {!selectedUser && (
                <IconButton
                  data-testid="user-accounts-lock-btn"
                  size="small"
                  aria-label="user-accounts-lock-btn"
                  disabled
                >
                  <LockIcon color="disabled" />
                </IconButton>
              )}
            </>
            {selectedUser && (
              <IconButton
                data-testid="user-accounts-lock-btn"
                onClick={() => handlelockUnlockBtnClick(id)}
                size="small"
                aria-label="user-accounts-lock-unlock-btn"
              >
                {locked ? <LockIcon /> : <LockOpenIcon />}
              </IconButton>
            )}

            <div>{label}</div>
          </div>
        );
      })
    );
  };

  const [selectedIndex, setSelectedIndex] = React.useState(-1);

  const handleListItemClick = (event, index, user) => {
    setSelectedIndex(index);
    setSelectedUser(user.userId);
  };

  return (
    <>
      <button
        className="user-detail__user_accounts"
        type="button"
        data-testid="user_accounts-btn"
        onClick={openUserAccountsDialog}
        aria-label="user_accounts-btn"
      >
        <PeopleAlt />
        {Constants.USER_ACCOUNTS}
      </button>
      <CustomDialog
        onClose={closeUserAccountDialog}
        open={openDialog && userAffiliations.length > 0}
        className="user-accounts-dialog"
        maxWidth={false}
      >
        <div className="dialog-header">
          <DialogHeader
            onClose={closeUserAccountDialog}
            title="User Accounts"
          />
        </div>
        <DialogContent>
          <div>
            <div className="user-accounts-dialog__content">
              <div>
                <div className="sub-heading">{BUSINESS_UNIT_AFFILIATIONS}</div>
                {renderBusinessAffiliations()}
              </div>

              <div>
                <div className="sub-heading">{USERS}</div>
                <List component="nav" aria-label="secondary mailbox folder">
                  {allUsersInfo &&
                    allUsersInfo.map((item, i) => (
                      <ListItem
                        key={item.userId}
                        button
                        selected={selectedIndex === i}
                        data-testId={`list-item-${i}`}
                        onClick={event => handleListItemClick(event, i, item)}
                        aria-label="user-accounts-user-list"
                      >
                        <ListItemText
                          primary={`${item.userId} | ${item.email}`}
                        />
                      </ListItem>
                    ))}
                </List>
              </div>
            </div>
          </div>
          <div className="user-accounts-dialog__dialog-actions">
            <Button
              className="btnCancel"
              data-testid="btn-cancel"
              onClick={closeUserAccountDialog}
              aria-label="user-accounts-btn-cancel"
            >
              {CANCEL}
            </Button>
            <Button
              className="btnSave"
              data-testid="btn-save"
              disabled={!isSaveEnabled}
              onClick={handleSaveUserAccount}
              aria-label="user-accounts-btn-save"
            >
              {SAVE}
            </Button>
          </div>
        </DialogContent>
      </CustomDialog>
    </>
  );
};

UserAccounts.propTypes = {
  store: PropTypes.shape({
    AllUsersInfo: PropTypes.shape({
      allUsersInfo: PropTypes.arrayOf({
        email: PropTypes.string,
        userId: PropTypes.string
      }),
      setAllUsersInfo: PropTypes.func.isRequired
    }),
    BusinessUnits: PropTypes.shapeOf({
      allBusinessUnits: PropTypes.arrayOf({
        id: PropTypes.string,
        label: PropTypes.string
      })
    })
  })
};

export default hot(inject('store')(observer(UserAccounts)));
