/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S.
 * and Foreign Patents, patent applications, and are protected by trade secret
 * or copyright law. Dissemination of this information, reproduction of this
 * material, and copying or distribution of this software is strictly forbidden
 * unless prior written permission is obtained from Pearson Education, Inc.
 */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from 'react';
import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';
import { isEmpty, cloneDeep } from 'lodash';
import { Button, Tooltip, IconButton } from '@material-ui/core';
import TuneIcon from '@material-ui/icons/Tune';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import arrayMove from 'array-move';
import {
  sortableContainer,
  sortableElement,
  sortableHandle
} from 'react-sortable-hoc';
import PropTypes from 'prop-types';
import { ListViewIcon } from '../../custom/icons/ListView';
import { GridViewIcon } from '../../custom/icons/GridView';
import { TableViewIcon } from '../../custom/icons/TableView';
import { CustomDialog, DialogContent, DialogHeader } from '../common/Dialog';
import {
  getDistributionOptions as DistributionConstants,
  statusOptions as StatusConstants
} from '../../constants/projectStatusAndPlatformConstants';
import TableModeConstants from '../../constants/tableModeConstants';
import { saveUserPrefernce } from '../../services/preferences.service';
import Select from '../common/select/Select';
import Constants from '../../constants';
import './PreferencesDialog.style.scss';

const PreferencesDialog = props => {
  const {
    store: {
      UserPreference,
      UserPreferenceMasterData: {
        layouts,
        filters: filterOptions,
        platforms: platformOptions,
        status: statusOptions,
        tableModeColumns: tableColumns
      }
    }
  } = props;
  const {
    filter: savedFilter,
    status: savedStatus,
    layout: savedLayout,
    platforms: savedPlatforms,
    tableModeColumns: savedTableModeColumns
  } = UserPreference;

  const [
    userPreferenceStatusOptions,
    setUserPreferenceStatusOptions
  ] = useState([]);

  // Adding properties checked and visible to platforms comparing with userPreference data
  const updateUserPreferencePlatforms = () => {
    const platformOptionsCopy = cloneDeep(platformOptions);
    if (savedPlatforms) {
      platformOptionsCopy.forEach(item => {
        const platform = item;
        platform.checked =
          savedPlatforms.includes(platform.label) ||
          savedPlatforms.includes('default');
        const inReviewOrPlublishedLiveStatus =
          savedStatus === StatusConstants.IN_REVIEW ||
          savedStatus === StatusConstants.PUBLISHED_LIVE;
        if (
          platform.id === DistributionConstants.COL_GPS_ID ||
          platform.id === DistributionConstants.COL_VIRTUALSCHOOLS_ID
        ) {
          platform.visible = !inReviewOrPlublishedLiveStatus;
        }
      });
    }
    return platformOptionsCopy;
  };

  // Hiding In Review and Published Live status options when only GPS and Virtual schools selected in platforms
  const showHideDistributableOnlyStatus = hide => {
    const statusOptionsCopy = cloneDeep(statusOptions);
    statusOptionsCopy.forEach(item => {
      const status = item;
      if (
        status.label === StatusConstants.IN_REVIEW ||
        status.label === StatusConstants.PUBLISHED_LIVE
      ) {
        status.visible = !hide;
      }
      setUserPreferenceStatusOptions(statusOptionsCopy);
    });
  };

  // Hiding In Review and Published Live status options when only GPS and Virtual schools selected in platforms
  const updateStatusBasedOnPlatform = platforms => {
    let hideDistributableOnlyStatus = false;
    if (
      platforms.includes(Constants.STATUS_FILTER_DEFAULT) ||
      platforms.includes(Constants.STATUS_FILTER_NONE)
    ) {
      setUserPreferenceStatusOptions(statusOptions);
      return;
    }
    if (
      platforms.length === 2 &&
      platforms.includes(DistributionConstants.COL_GPS_LABEL) &&
      platforms.includes(DistributionConstants.COL_VIRTUALSCHOOLS_LABEL)
    ) {
      hideDistributableOnlyStatus = true;
    } else if (
      platforms.length === 1 &&
      (platforms.includes(DistributionConstants.COL_GPS_LABEL) ||
        platforms.includes(DistributionConstants.COL_VIRTUALSCHOOLS_LABEL))
    ) {
      hideDistributableOnlyStatus = true;
    } else {
      hideDistributableOnlyStatus = false;
    }
    showHideDistributableOnlyStatus(hideDistributableOnlyStatus);
  };

  // Tracking all the elements whenever it is changed for enabling/disabling save button
  const elementsToTrackChange = {
    layout: false,
    filters: false,
    status: false,
    platform: false,
    table: false
  };
  const { isOpen, close } = props;
  const { SAVE, CANCEL } = Constants;

  const [layout, setLayout] = useState(savedLayout);
  const [projectFilter, setProjectFilter] = useState(savedFilter);
  const [projectStatus, setProjectStatus] = useState(savedStatus);
  const [userPreferencePlatforms, setUserPreferencePlatforms] = useState(
    updateUserPreferencePlatforms()
  );
  const [
    userPreferenceTableModeColumns,
    setUserPreferenceTableModeColumns
  ] = useState(savedTableModeColumns || tableColumns);
  const [anchorEl, setAnchorEl] = useState(null);
  const [userPreferenceChanges, setUserPreferenceChanges] = useState(
    elementsToTrackChange
  );
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);

  useEffect(() => {
    updateStatusBasedOnPlatform(savedPlatforms);
  }, [isOpen]);

  useEffect(() => {
    const isUSerPreferenceChanged = Object.values(userPreferenceChanges).some(
      item => item === true
    );
    setIsSaveDisabled(!isUSerPreferenceChanged);
  }, [userPreferenceChanges]);

  const onLayoutChange = (event, newView) => {
    if (newView) {
      setLayout(newView);
    }
    const isLayoutChanged = newView !== savedLayout;
    const temp = cloneDeep(userPreferenceChanges);
    setUserPreferenceChanges({ ...temp, layout: isLayoutChanged });
  };

  const onProjectFilterChange = ({ value }) => {
    setProjectFilter(value);
    const isFilterChanged = value !== savedFilter;
    const temp = cloneDeep(userPreferenceChanges);
    setUserPreferenceChanges({ ...temp, filter: isFilterChanged });
  };

  const openPlatformSelectionMenu = event => {
    setAnchorEl(event.currentTarget);
  };

  const closePlatformSelectionMenu = () => {
    setAnchorEl(null);
  };

  // IF all platforms are selected returning "default" and if none selected returning "None"
  const getAllCheckedPlatforms = (status, selectedPlatforms) => {
    const checkedOptions = [];
    // retrive all selcted options and in newArray
    selectedPlatforms.forEach(column => {
      if (column.checked && column.visible) {
        checkedOptions.push(column.label);
      }
    });
    if (
      checkedOptions.length === selectedPlatforms.length ||
      ((status === StatusConstants.IN_REVIEW ||
        status === StatusConstants.PUBLISHED_LIVE) &&
        checkedOptions.length === selectedPlatforms.length - 2)
    ) {
      return [Constants.STATUS_FILTER_DEFAULT];
    }
    if (isEmpty(checkedOptions)) {
      return [Constants.STATUS_FILTER_NONE];
    }
    return checkedOptions;
  };

  // Check if all platforms are selected
  const getShowAllPlatformsValue = status => {
    if (
      status === StatusConstants.IN_REVIEW ||
      status === StatusConstants.PUBLISHED_LIVE
    ) {
      let length = 0;
      userPreferencePlatforms.forEach(column => {
        if (column.checked && column.visible) {
          length += 1;
        }
      });
      // here we are skipping GPS option for In review and publish live statusses
      // in case of In reveiw and publish Live check if the length of selected value is one two(for GPS & virtual schools) then equal to length of all Options then return true
      if (length === userPreferencePlatforms.length - 2) {
        return true;
      }
      return false;
    }
    return userPreferencePlatforms.every(option => option.checked);
  };

  const showAllPlatforms = getShowAllPlatformsValue(projectStatus);

  // show All option update on platforms
  const updateAllPlatformsVisibility = value => {
    const userPreferencePlatformsCopy = cloneDeep(userPreferencePlatforms);
    userPreferencePlatformsCopy.forEach(item => {
      const column = item;
      column.checked = value;
    });
    setUserPreferencePlatforms(userPreferencePlatformsCopy);

    const formattedPlatforms = getAllCheckedPlatforms(
      projectStatus,
      userPreferencePlatformsCopy
    );
    const temp = cloneDeep(userPreferenceChanges);
    const isPlatformsChanged =
      JSON.stringify(formattedPlatforms) !== JSON.stringify(savedPlatforms);
    setUserPreferenceChanges({ ...temp, platform: isPlatformsChanged });
  };

  // Platform menu - show All option on click set visibility
  const handleShowAllPlatformClick = () => {
    updateAllPlatformsVisibility(!showAllPlatforms);
    setUserPreferenceStatusOptions(statusOptions);
  };

  // update the check/uncheck property of selected option
  const updatePlatformVisibility = (id, value) => {
    const userPreferencePlatformsCopy = cloneDeep(userPreferencePlatforms);
    const matchedPlatform = userPreferencePlatformsCopy.find(
      platform => platform.id === id
    );
    matchedPlatform.checked = value;
    setUserPreferencePlatforms(userPreferencePlatformsCopy);
    const updatedPlatforms = userPreferencePlatformsCopy
      .filter(platform => platform.checked === true)
      .map(item => item.label);

    let isPlatformsChanged;
    const formattedPlatforms = getAllCheckedPlatforms(
      projectStatus,
      userPreferencePlatformsCopy
    );
    if (JSON.stringify(formattedPlatforms) === JSON.stringify(savedPlatforms)) {
      isPlatformsChanged = false;
    } else {
      isPlatformsChanged =
        JSON.stringify(updatedPlatforms.sort()) !==
        JSON.stringify(toJS(savedPlatforms).sort());
    }
    const temp = cloneDeep(userPreferenceChanges);
    setUserPreferenceChanges({ ...temp, platform: isPlatformsChanged });

    updateStatusBasedOnPlatform(formattedPlatforms);
  };

  // update the check/uncheck property of selected option
  const onPlatformCheckboxChange = (id, checked) => {
    updatePlatformVisibility(id, !checked);
  };

  const renderPlatforms = () => {
    return userPreferencePlatforms.map(option => {
      let element;
      if (option.visible) {
        element = (
          <MenuItem key={option.id}>
            <FormControlLabel
              control={
                <Checkbox
                  value={option.label}
                  checked={option.checked}
                  color="primary"
                  onClick={() =>
                    onPlatformCheckboxChange(option.id, option.checked)
                  }
                  disabled={option.disable}
                  inputProps={{
                    'data-testid': `${option.id}-platform-checkbox`,
                    'aria-label': `publish-platform-options-${option.label}`
                  }}
                />
              }
              label={option.label}
              key={option.id}
            />
          </MenuItem>
        );
      }
      return element;
    });
  };

  const getStatusMenuOptions = () => {
    return (
      userPreferenceStatusOptions &&
      userPreferenceStatusOptions.filter(option => option.visible)
    );
  };

  const updatePlatformBasedOnStatus = status => {
    // if status is in review or published live set GPS option and virtual schools visible as false
    const userPreferencePlatformsCopy = cloneDeep(userPreferencePlatforms);
    const gpsOption = userPreferencePlatformsCopy.find(
      option => option.id === DistributionConstants.COL_GPS_ID
    );
    const virtualSchoolsOption = userPreferencePlatformsCopy.find(
      option => option.id === DistributionConstants.COL_VIRTUALSCHOOLS_ID
    );
    if (
      status === StatusConstants.IN_REVIEW ||
      status === StatusConstants.PUBLISHED_LIVE
    ) {
      gpsOption.visible = false;
      virtualSchoolsOption.visible = false;
    } else {
      gpsOption.visible = true;
      virtualSchoolsOption.visible = true;
    }
    setUserPreferencePlatforms(userPreferencePlatformsCopy);
  };

  const onProjectStatusChange = ({ value }) => {
    setProjectStatus(value);
    updatePlatformBasedOnStatus(value);
    const temp = cloneDeep(userPreferenceChanges);
    const isStatusChanged = value !== savedStatus;
    setUserPreferenceChanges({ ...temp, status: isStatusChanged });
  };

  useEffect(() => {
    // updating userPreferenceChanges for table as true when column order,visibility gets changed
    const temp = cloneDeep(userPreferenceChanges);
    const isTableColumnsChanged =
      JSON.stringify(userPreferenceTableModeColumns) !==
      JSON.stringify(toJS(savedTableModeColumns || tableColumns));
    setUserPreferenceChanges({ ...temp, table: isTableColumnsChanged });
  }, [userPreferenceTableModeColumns]);

  const getShowAllTableColumnsValue = () => {
    const userPreferenceTableModeColumnsCopy = cloneDeep(
      userPreferenceTableModeColumns
    );
    return userPreferenceTableModeColumnsCopy.every(option => option.visible);
  };

  const showAllTableColumns = getShowAllTableColumnsValue();

  const updateTableColumnVisibility = (id, value) => {
    const columns = cloneDeep(userPreferenceTableModeColumns);
    const column = columns.find(el => el.id === id);
    column.visible = !value;
    setUserPreferenceTableModeColumns(columns);
  };

  // show all option update for table columns
  const updateAllTableColumnsVisibility = value => {
    const userPreferenceTableModeColumnsCopy = cloneDeep(
      userPreferenceTableModeColumns
    );
    userPreferenceTableModeColumnsCopy.forEach(item => {
      const column = item;
      if (!column.mandatory) {
        column.visible = value;
      }
    });
    setUserPreferenceTableModeColumns(userPreferenceTableModeColumnsCopy);
  };

  const handleShowAllTableColumnsClick = () => {
    updateAllTableColumnsVisibility(!showAllTableColumns);
  };

  const onColumnSortEnd = options => {
    const { oldIndex, newIndex } = options;
    const dragItem = userPreferenceTableModeColumns[oldIndex];

    if (dragItem) {
      const coppiedStateArray = cloneDeep(userPreferenceTableModeColumns);
      const modifiedArray = arrayMove(coppiedStateArray, oldIndex, newIndex);
      const updatedColumns = modifiedArray.map((column, index) => ({
        ...column,
        columnOrder: index
      }));
      setUserPreferenceTableModeColumns(updatedColumns);
    }
  };

  const DragHandle = sortableHandle(() => <DragHandleIcon />);

  const SortableContainer = sortableContainer(({ children }) => {
    return (
      <div className="right-side-wrapper_columns-sortable-list">{children}</div>
    );
  });

  const SortableItem = sortableElement(({ option, sortIndex }) => (
    <div className={`right-side-wrapper_columns-${option.id} column-wrapper`}>
      <FormControlLabel
        control={
          <Checkbox
            data-testid={option.label}
            value={option.id}
            checked={option.visible}
            color="primary"
            onClick={() =>
              updateTableColumnVisibility(option.id, option.visible)
            }
            disabled={option.mandatory}
            inputProps={{
              'aria-label': `table-view-${option.label}-checkbox`,
              'data-testid': `table-${option.id}-column-checkbox`
            }}
          />
        }
        label={option.label}
        key={option.id}
      />
      <DragHandle sortIndex={sortIndex} />
    </div>
  ));

  const savePreference = async () => {
    const tableColumnClone = toJS(userPreferenceTableModeColumns);
    const data = {
      layout,
      filter: projectFilter,
      status: projectStatus,
      platforms: getAllCheckedPlatforms(projectStatus, userPreferencePlatforms),
      tableModeColumns: tableColumnClone
    };
    await saveUserPrefernce(data);

    // Redirect to home page
    window.location.assign('/');
  };

  const cancelDialog = () => {
    setLayout(savedLayout);
    setProjectFilter(savedFilter);
    setProjectStatus(savedStatus);
    setUserPreferencePlatforms(updateUserPreferencePlatforms());
    setUserPreferenceTableModeColumns(savedTableModeColumns || tableColumns);
    setIsSaveDisabled(true);
    close();
  };

  return (
    <>
      <CustomDialog
        onClose={cancelDialog}
        open={isOpen}
        className="preference-dialog"
        maxWidth={false}
      >
        <div className="dialog-header">
          <DialogHeader
            onClose={cancelDialog}
            title={Constants.USER_PREFERENCE}
          />
        </div>
        <DialogContent
          className="preference-content"
          data-testid="preferenceDialogContent"
        >
          <div className="preference-content_wrapper">
            <div className="left-side-wrapper">
              {/* Layouts preference */}
              <div className="left-side-wrapper_layouts">
                <div className="control-label">{Constants.LAYOUTS}</div>
                <ToggleButtonGroup
                  size="small"
                  value={layout}
                  exclusive
                  onChange={onLayoutChange}
                  aria-label="text alignment"
                >
                  {layouts.map(_layout => (
                    <ToggleButton
                      key={_layout.id}
                      value={_layout.id}
                      aria-label={_layout.label}
                      data-testid={`${_layout.id}-layout-button`}
                    >
                      <Tooltip title={_layout.label}>
                        <span className="view-Selector__icon-container">
                          {_layout.id === 'grid' && <GridViewIcon />}
                          {_layout.id === 'table' && <TableViewIcon />}
                          {_layout.id === 'list' && <ListViewIcon />}
                        </span>
                      </Tooltip>
                    </ToggleButton>
                  ))}
                </ToggleButtonGroup>
              </div>

              {/* Project filters preference */}
              <div className="left-side-wrapper_filters">
                <label htmlFor="filters-select" className="control-label">
                  {Constants.FILTERS}
                </label>
                <div className="sort-select">
                  <Select
                    id="filters-select"
                    optionsClassName="sort-select__menu-item"
                    disableUnderline
                    options={filterOptions}
                    value={projectFilter}
                    onChange={onProjectFilterChange}
                    inputProps={{
                      'data-testid': 'project-filter-select',
                      'aria-label': 'preference-sort-select'
                    }}
                  />
                </div>
              </div>

              {/* Project status preference */}
              <div className="left-side-wrapper_status">
                <label htmlFor="projectStatusSelect" className="control-label">
                  {Constants.STATUS}
                </label>
                <div className="sort-select">
                  <Select
                    id="projectStatusSelect"
                    className="sort-select__select"
                    optionsClassName="sort-select__menu-item"
                    disableUnderline
                    options={getStatusMenuOptions()}
                    value={projectStatus}
                    onChange={onProjectStatusChange}
                    inputProps={{
                      'data-testid': 'project-status-select',
                      'aria-label': 'project-status-select'
                    }}
                  />
                </div>
              </div>

              {/* Product model preference */}
              <div className="left-side-wrapper_platform">
                <div className="control-label">{Constants.PRODUCT_MODEL}</div>
                <div>
                  <div
                    className="distribution-status-select"
                    data-testid="distribution-status-select"
                  >
                    <div className="distribution-status-select__icon">
                      <Tooltip title={Constants.PRODUCT_PLATFORM_TOOLTIP}>
                        <IconButton
                          aria-controls="distribution-status-menu"
                          aria-haspopup="true"
                          onClick={openPlatformSelectionMenu}
                          data-testid="distribution-status-menu"
                          aria-label="distribution-status-menu"
                        >
                          <TuneIcon />
                          {Boolean(anchorEl) === true ? (
                            <ExpandLessIcon />
                          ) : (
                            <ExpandMoreIcon />
                          )}
                        </IconButton>
                      </Tooltip>
                    </div>
                    <Menu
                      id="publish-platform-menu"
                      data-testid="publish-platform-menu"
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={closePlatformSelectionMenu}
                    >
                      <MenuItem key="showAllMenuItem">
                        <FormControlLabel
                          control={
                            <Checkbox
                              value={DistributionConstants.SHOW_ALL_LABEL}
                              checked={showAllPlatforms}
                              color="primary"
                              onClick={handleShowAllPlatformClick}
                              inputProps={{
                                'data-testid': 'show-all-platforms-checkbox',
                                'aria-label': `publish-platform-options-show-all}`
                              }}
                            />
                          }
                          label={DistributionConstants.SHOW_ALL_LABEL}
                          key={DistributionConstants.SHOW_ALL_ID}
                        />
                      </MenuItem>
                      {renderPlatforms()}
                    </Menu>
                  </div>
                </div>
              </div>
            </div>

            <div className="right-side-wrapper">
              <div className="right-side-wrapper_columns">
                {/* Table mode columns */}
                <div className="right-side-wrapper_columns-showAll">
                  <FormControlLabel
                    control={
                      <Checkbox
                        data-testid="show-all-menu-item"
                        value={TableModeConstants.SHOW_ALL_ID}
                        checked={showAllTableColumns}
                        color="primary"
                        onClick={handleShowAllTableColumnsClick}
                        inputProps={{
                          'aria-label': 'table-view-show-all-columns-checkbox',
                          'data-testid': `table-show-all-checkbox`
                        }}
                      />
                    }
                    label={TableModeConstants.SHOW_ALL_LABEL}
                    key={TableModeConstants.SHOW_ALL_ID}
                  />
                </div>
                <SortableContainer
                  onSortEnd={onColumnSortEnd}
                  helperClass="table-columns-sortable-helper"
                  useDragHandle
                >
                  {userPreferenceTableModeColumns.map((option, index) => {
                    return (
                      <SortableItem
                        key={option.id}
                        index={index}
                        sortIndex={index}
                        option={option}
                      />
                    );
                  })}
                </SortableContainer>
              </div>
            </div>
          </div>
          <div className="preference-content__dialog-actions">
            <Button
              className="btnCancel"
              data-testid="btn-cancel"
              onClick={cancelDialog}
            >
              {CANCEL}
            </Button>
            <Button
              className="btnSave"
              data-testid="btn-save"
              disabled={isSaveDisabled}
              onClick={savePreference}
            >
              {SAVE}
            </Button>
          </div>
        </DialogContent>
      </CustomDialog>
    </>
  );
};

export default inject('store')(observer(PreferencesDialog));

PreferencesDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  store: {
    UserPreference: PropTypes.shape({
      filter: PropTypes.string.isRequired,
      status: PropTypes.string.isRequired,
      layout: PropTypes.string.isRequired,
      platforms: PropTypes.arrayOf(PropTypes.string)
    }),
    UserPreferenceMasterData: PropTypes.shape({
      layouts: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          label: PropTypes.string.isRequired
        })
      ).isRequired,
      filters: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.string.isRequired,
          label: PropTypes.string.isRequired
        })
      ).isRequired,
      platforms: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          label: PropTypes.string.isRequired,
          checked: PropTypes.string.bool,
          visible: PropTypes.string.bool
        })
      ).isRequired,
      status: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string.isRequired,
          value: PropTypes.string.isRequired,
          visible: PropTypes.string.bool
        })
      ).isRequired,
      tableModeColumns: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          label: PropTypes.string.isRequired,
          visible: PropTypes.bool.isRequired,
          draggable: PropTypes.bool.isRequired,
          columnOrder: PropTypes.number.isRequired,
          mandatory: PropTypes.bool.isRequired,
          sortOn: PropTypes.string.isRequired
        })
      ).isRequired,
      updateTableColumns: PropTypes.func.isRequired,
      updateStatusBasedOnPlatform: PropTypes.func.isRequired,
      getShowAllPlatformsValue: PropTypes.func.isRequired,
      updatePlatformVisibility: PropTypes.func.isRequired,
      updateAllPlatformsVisibility: PropTypes.func.isRequired,
      getAllCheckedPlatforms: PropTypes.func.isRequired,
      updatePlatformBasedOnStatus: PropTypes.func.isRequired,
      updateAllTableColumnsVisibility: PropTypes.func.isRequired,
      updatePreferenceMasterData: PropTypes.func.isRequired
    })
  }
};
