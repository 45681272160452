/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S.
 * and Foreign Patents, patent applications, and are protected by trade secret
 * or copyright law. Dissemination of this information, reproduction of this
 * material, and copying or distribution of this software is strictly forbidden
 * unless prior written permission is obtained from Pearson Education, Inc.
 */
import { axiosFetch, getCancelSource } from './axiosFetch';
import API_CONSTANTS from '../constants/apiConstants';
import constants from '../constants';
import { getHeaders, postHeaders } from '../utils';

const {
  GET_FAVOURITE_PROJECTS,
  GET_LAST_OPENED_BY_ME_PROJECTS,
  GET_PROJECTS,
  GET_REQUEST_ACCESS_PROJECTS,
  GET_ENTITY_OR_PRODUCTID_BY_SEARCH_QUERY
} = API_CONSTANTS;

const { FAVORITE_FILTER, LAST_OPEN_BY_ME_FILTER, HIDDEN } = constants;

const getPageSize = viewmode => {
  if (viewmode === constants.LIST_VIEW_MODE) {
    return 5;
  }
  if (
    viewmode === constants.TABLE_VIEW_MODE ||
    viewmode === constants.REQUEST_PROJECT_ACCESS_MODE
  ) {
    return 8;
  }
  return 20;
};

const getAPIPath = sort => {
  let apiPath;

  switch (sort) {
    case FAVORITE_FILTER:
      apiPath = GET_FAVOURITE_PROJECTS;
      break;
    case LAST_OPEN_BY_ME_FILTER:
      apiPath = GET_LAST_OPENED_BY_ME_PROJECTS;
      break;
    default:
      apiPath = GET_PROJECTS;
  }

  return apiPath;
};

/**
 * Retrieve project list from the dashboard api based on page index
 * @param {*} page
 */

// Holds the cancelSource object to cancel the prevous call
let cancelSource;

const getParam = ({
  page,
  searchTerm,
  lineOfBusiness,
  // This is for global sort/filter
  sort,
  // This property will be there only for table mode
  sortAttribute,
  // This property will be there only for table mode
  sortOrder,
  viewmode,
  projectPublishStatus,
  projectPublishPlatform,
  category,
  requestAccessMode
}) => {
  const params = {
    page,
    ...(searchTerm && { searchTerm }),
    // add sorting attributes and its ordering params
    ...(sortAttribute && sortOrder && { sortAttribute, sortOrder }),
    // add lineofbusiness param only when its value is not null and not none(default for UI)
    ...(lineOfBusiness &&
      lineOfBusiness !== constants.DEFAULT_SELECTED_LOB && { lineOfBusiness }),
    requestAccessMode
  };

  params.viewmode =
    requestAccessMode === true ? constants.TABLE_VIEW_MODE : viewmode;
  if (projectPublishStatus !== constants.STATUS_FILTER_DEFAULT) {
    params.status = projectPublishStatus;
  }

  if (
    projectPublishPlatform &&
    projectPublishPlatform !== constants.STATUS_FILTER_DEFAULT &&
    !projectPublishPlatform.includes(constants.VIRTUALSCHOOLS)
  ) {
    params.productPlatform = projectPublishPlatform;
  } else if (
    projectPublishPlatform &&
    projectPublishPlatform.includes(constants.VIRTUALSCHOOLS)
  ) {
    // if platform values includes virtual schools, changing value from virtual schools to 'eText PCX'
    const platformValues = projectPublishPlatform.split(',');
    const pos = platformValues.indexOf(constants.VIRTUALSCHOOLS);
    platformValues[pos] = `${constants.ETEXT} ${constants.DESTINATION_PCX}`;
    params.productPlatform = platformValues.join();
  } else {
    // Do Nothing
  }

  // todo: configure this task def? but default value if not found should be 5
  params.pagesize = getPageSize(viewmode);

  if (sort === HIDDEN) {
    params.isDashboard = false;
  }

  if (category && category !== constants.STATUS_FILTER_DEFAULT) {
    params.category = category;
  }

  return params;
};

export const getProjects = (
  {
    page,
    searchTerm,
    lineOfBusiness,
    // This is for global sort/filter
    sort,
    // This property will be there only for table mode
    sortAttribute,
    // This property will be there only for table mode
    sortOrder,
    viewmode = constants.GRID_VIEW_MODE,
    projectPublishStatus,
    projectPublishPlatform,
    category,
    useOldSearchAPI
  },
  skipCancel,
  requestAccessMode,
  entityUrnsOrProductIds
) => {
  const apiPath =
    requestAccessMode === true ? GET_REQUEST_ACCESS_PROJECTS : getAPIPath(sort);

  // Canceling the previous request
  if (cancelSource && !skipCancel) {
    cancelSource.cancel('Operation canceled');
  }

  cancelSource = getCancelSource();

  let reqOptions = {
    ...getHeaders(),
    cancelToken: cancelSource.token
  };

  const params = getParam({
    page,
    searchTerm,
    lineOfBusiness,
    // This is for global sort/filter
    sort,
    // This property will be there only for table mode
    sortAttribute,
    // This property will be there only for table mode
    sortOrder,
    viewmode,
    projectPublishStatus,
    projectPublishPlatform,
    category,
    requestAccessMode
  });

  params.useOldSearchAPI = useOldSearchAPI;

  if (entityUrnsOrProductIds) {
    reqOptions = {
      ...postHeaders(),
      cancelToken: cancelSource.token,
      body: {
        ...entityUrnsOrProductIds,
        data: true
      }
    };
    return axiosFetch(reqOptions, apiPath, params).then(response => {
      cancelSource = null;
      return response.data;
    });
  }

  return axiosFetch(reqOptions, apiPath, params).then(response => {
    cancelSource = null;
    return response.data;
  });
};

export const getEntityUrnsOrProductIdsBySearchParam = (
  {
    page,
    searchTerm,
    lineOfBusiness,
    // This is for global sort/filter
    sort,
    // This property will be there only for table mode
    sortAttribute,
    // This property will be there only for table mode
    sortOrder,
    viewmode = constants.GRID_VIEW_MODE,
    projectPublishStatus,
    projectPublishPlatform,
    category
  },
  skipCancel,
  requestAccessMode
) => {
  const apiPath = GET_ENTITY_OR_PRODUCTID_BY_SEARCH_QUERY;

  // Canceling the previous request
  if (cancelSource && !skipCancel) {
    cancelSource.cancel('Operation canceled');
  }

  cancelSource = getCancelSource();

  const reqOptions = {
    ...getHeaders(),
    cancelToken: cancelSource.token
  };

  const params = getParam({
    page,
    searchTerm,
    lineOfBusiness,
    // This is for global sort/filter
    sort,
    // This property will be there only for table mode
    sortAttribute,
    // This property will be there only for table mode
    sortOrder,
    viewmode,
    projectPublishStatus,
    projectPublishPlatform,
    category,
    requestAccessMode
  });

  params.sort = sort;

  return axiosFetch(reqOptions, apiPath, params).then(response => {
    cancelSource = null;
    return response.data;
  });
};
