/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S.
 * and Foreign Patents, patent applications, and are protected by trade secret
 * or copyright law. Dissemination of this information, reproduction of this
 * material, and copying or distribution of this software is strictly forbidden
 * unless prior written permission is obtained from Pearson Education, Inc.
 */

import ProjectList from './ProjectList';
import UserInfo from './UserInfo';
import Header from './Header';
import Loader from './Loader';
import Config from './Config';
import AlertMessage from './AlertMessage';
import LineOfBusiness from './LineOfBusiness';
import Project from './Project';
import Filters from './Filters';
import ItemBanks from './ItemBanks';
import SettingsDrawer from './SettingsDrawer';
import MessageDialog from './MessageDialog';
import FavoriteProjects from './FavoriteProjects';
import Product from './Product';
import GoogleAnalytics from './GoogleAnalytics';
import BuildInfo from './BuildInfo';
import UserManagement from './UserManagement';
import UserRoles from './UserRoles';
import Permissions from './Permissions';
import TableModeSettings from './TableModeSettings';
import RequestAccessSettings from './RequestAccessSettings';
import PublishPlatformOptions from './PublishPlatformOptions';
import SpecialPermissions from './SpecialPermissions';
import PageTour from './PageTour';
import NotificationDrawer from './NotificationDrawer';
import NTData from './NTData';
import LearningFramework from './LearningFramework';
import LobWorkflow from './LobWorkflow';
import CypressRules from './CypressRules';
import Theme from './Theme';
import Subject from './Subject';
import KeyStage from './KeyStage';
import AddUsersToProjects from './AddUsersToProjects';
import BusinessUnits from './BusinessUnits';
import AllUsersInfo from './AllUsersInfo';
import FileUrls from './FileUrls';
import FeatureManager from './FeatureManager';
import UserPreference from './UserPreference';
import UserPreferenceMasterData from './UserPreferenceMasterData';
import ProjectTypeFilterOptions from './ProjectTypeFilterOptions';
import LobMetaManager from './LobMetaManager';
import LobMeta from './LobMeta';
import LobMetaMaster from './LobMetaMaster';

export default {
  ProjectGridView: ProjectList.create(),
  ProjectListView: ProjectList.create(),
  ProjectTableView: ProjectList.create(),
  RequestProjectAccessView: ProjectList.create(),
  UserInfo: UserInfo.create(),
  Header: Header.create(),
  Loader: Loader.create(),
  Config: Config.create(),
  AlertMessage: AlertMessage.create(),
  LineOfBusiness: LineOfBusiness.create(),
  LearningFramework: LearningFramework.create(),
  ProjectDetail: Project.create(),
  Filters: Filters.create(),
  ItemBank: ItemBanks.create(),
  SettingsDrawer: SettingsDrawer.create(),
  MessageDialog: MessageDialog.create(),
  FavoriteProjects: FavoriteProjects.create(),
  Product: Product.create(),
  GoogleAnalytics: GoogleAnalytics.create(),
  BuildInfo: BuildInfo.create(),
  UserManagement: UserManagement.create(),
  UserRoles: UserRoles.create(),
  Permissions: Permissions.create(),
  TableModeSettings: TableModeSettings.create(),
  RequestAccessSettings: RequestAccessSettings.create(),
  PublishPlatformOptions: PublishPlatformOptions.create(),
  SpecialPermissions: SpecialPermissions.create(),
  PageTour: PageTour.create(),
  NotificationDrawer: NotificationDrawer.create(),
  NTData: NTData.create(),
  LobWorkflow: LobWorkflow.create(),
  CypressRules: CypressRules.create(),
  ThemeData: Theme.create(),
  Subject: Subject.create(),
  KeyStage: KeyStage.create(),
  AddUsersToProjects: AddUsersToProjects.create(),
  BusinessUnits: BusinessUnits.create(),
  AllUsersInfo: AllUsersInfo.create(),
  FileUrls: FileUrls.create(),
  FeatureManager: FeatureManager.create(),
  UserPreference: UserPreference.create(),
  UserPreferenceMasterData: UserPreferenceMasterData.create(),
  ProjectTypeFilterOptions: ProjectTypeFilterOptions.create(),
  LobMeta: LobMeta.create(),
  LobMetaMaster: LobMetaMaster.create(),
  LobMetaManager: LobMetaManager.create()
};
