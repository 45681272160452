/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S.
 * and Foreign Patents, patent applications, and are protected by trade secret
 * or copyright law. Dissemination of this information, reproduction of this
 * material, and copying or distribution of this software is strictly forbidden
 * unless prior written permission is obtained from Pearson Education, Inc.
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './Slide.style.scss';
import CircularProgress from '@material-ui/core/CircularProgress';

/**
 * Slide component renders single image for carousel
 * @param {*} props
 */
const Slide = props => {
  const { content } = props;
  const [loaded, setLoaded] = useState(false);
  return (
    <div className="caraousel-slide">
      <img
        data-testid={content}
        src={content}
        alt={content}
        onLoad={() => setLoaded(true)}
      />
      {!loaded && (
        <CircularProgress className="spinner" size={50} disableShrink />
      )}
    </div>
  );
};

Slide.propTypes = {
  content: PropTypes.string.isRequired
};

export default Slide;
